import React from "react";
// import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import { IoMdGlobe } from "react-icons/io";
// import { IconContext } from "react-icons";
// import { ToolTipController, Select } from "react-tooltip-controller";

import DropDownButton from "../common/dropDownButton";
import useAnalytics from "../../analytics/useAnalytics";

const title = "Language";
const languageOptions = ["English", "简体中文"];

const LanguageSelector = () => {
  // const { i18n } = useTranslation();
  const history = useHistory();
  const { logEvent } = useAnalytics();

  const handleSelectLanguage = (e) => {
    switch (e) {
      case "简体中文":
        // i18n.changeLanguage("zn");
        history.push("./");
        logEvent(
          "change-language-to-Chinese",
          "change-language-to-Chinese-nav"
        );
        break;
      case "English":
        // i18n.changeLanguage("en");
        history.push("./en");
        logEvent(
          "change-language-to-English",
          "change-language-to-English-nav"
        );
        break;
      default:
        break;
    }
  };

  return (
    <DropDownButton
      title={title}
      options={languageOptions}
      onSelect={handleSelectLanguage}
      size="sm"
      variant="secondary"
    />

    // <ToolTipController
    //   detect="click"
    //   offsetX="center"
    //   offsetY="center"
    //   offsetY={5}
    // >
    //   <Select>
    //     <button
    //       className="mr-2 btn btn-link"
    //       style={{
    //         height: "32px",
    //         textAlign: "center",
    //         paddingBottom: "10px",
    //       }}
    //     >
    //       <IconContext.Provider
    //         value={{
    //           color: "rgb(33, 37, 41)",
    //           verticalAlign: "middle",
    //           paddingBottom: "10px",
    //         }}
    //       >
    //         <IoMdGlobe />
    //       </IconContext.Provider>
    //     </button>
    //   </Select>
    //   <div style={{ zIndex: 1999900000 }}>
    //     <button>English</button>
    //     <button>Chinese</button>
    //   </div>
    // </ToolTipController>
  );
};

export default LanguageSelector;
