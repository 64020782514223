import React from "react";

const SectionWrapper = ({
  children,
  isFullScreen,
  hasMarginTop = true,
  backgroundStyle,
  cardStyle,
  refProp,
}) => {
  return (
    <div
      className=" d-flex justify-content-center"
      style={{ width: "100%", ...backgroundStyle }}
      ref={refProp}
    >
      <div
        className="row mx-2"
        style={
          isFullScreen
            ? {
                height: "100vh",
                marginTop: "4rem",
                paddingBottom: "1rem",
                maxWidth: "1080px",
                ...cardStyle,
              }
            : {
                marginTop: hasMarginTop ? "6rem" : "4rem",
                marginBottom: "4rem",
                maxWidth: "1080px",
                ...cardStyle,
              }
        }
      >
        {children}
      </div>
    </div>
  );
};

export default SectionWrapper;
