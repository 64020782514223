import React from "react";

import Textarea from "../components/common/textarea";
import useDate from "../components/common/useDate";

const CaptionHead = ({ title, date, setTitle }) => {
  const { getDisplayDate } = useDate();
  const handleChange = (e) => {
    setTitle(e.currentTarget.value);
  };

  return (
    <div>
      <div>
        <Textarea
          value={title}
          onChange={handleChange}
          placeHolder={"Enter Title For This Caption"}
          style={{ fontSize: "30px" }}
        />
      </div>
      <div>
        <p>{getDisplayDate(date)}</p>
      </div>
    </div>
  );
};

export default CaptionHead;
