import React, { useEffect } from "react";
import autosize from "autosize";

const Textarea = ({ value, onChange, placeHolder, style }) => {
  useEffect(() => {
    autosize(document.querySelectorAll("textarea"));
  }, [value]);

  return (
    <div className="media text-muted w-100">
      <div className="media-body small lh-125  border-gray">
        <textarea
          value={value}
          onChange={onChange}
          type="text"
          rows="1"
          npm="true"
          className="form-control col-12"
          id="notes"
          name="notes"
          placeholder={placeHolder}
          style={{
            border: 0,
            display: "inline",
            fontFamily: "inherit",
            color: "#212529",
            backgroundColor: "inherit",
            padding: 0,
            width: "100%",
            ...style,
          }}
        />
      </div>
    </div>
  );
};

export default Textarea;
