import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useDate from "../../common/useDate";

const CaptionDocumentCard = ({ captionDocument }) => {
  const [transcriptionString, setTranscriptionString] = useState("");
  const { getDisplayDate } = useDate();

  const styles = {
    date: {
      fontSize: "12px",
      lineHeight: "14px",
      height: "14px",
      color: "#8D8D8D",
      textOverflow: "ellipsis",
      overflow: "hidden",
      marginBottom: "4px",
      paddingTop: "0px",
      fontWeight: "500",
    },
    title: {
      textTransform: "capitalize",
      fontSize: "17px",
      height: "18px",
      marginBottom: "10px",
      marginRight: "8px",
      color: "#33A747",
    },
    transcription: {
      fontSize: "14px",
      lineHeight: "19px",
      height: "19px",
      overflow: "hidden",
      marginBottom: "16px",
      color: "#373737",
    },
  };

  useEffect(() => {
    var transcription = "";
    var transcriptionArray = captionDocument.transcriptionArray;
    for (var i = 0; i < transcriptionArray.length; i++) {
      transcription += transcriptionArray[i].transcription;
    }
    setTranscriptionString(transcription);
  }, [captionDocument.transcriptionArray]);

  return (
    <Link to={`/caption/${captionDocument.id}`}>
      <div className="my-4 border-bottom">
        <div style={styles.date}>
          <span>{getDisplayDate(captionDocument.initDate)}</span>
        </div>
        <div style={styles.title}>
          <span>{captionDocument.title}</span>
        </div>
        <div style={styles.transcription}>
          <span>{transcriptionString}</span>
        </div>
      </div>
    </Link>
  );
};

export default CaptionDocumentCard;
