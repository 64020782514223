"use strict";
exports.__esModule = true;
var disableScrollPlugin = function () {
    var renderViewer = function (props) {
        var slot = props.slot;
        if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
            slot.subSlot.attrs.style = Object.assign({}, slot.subSlot.attrs.style, {
                // Disable scrolling in the pages container
                overflow: "hidden"
            });
        }
        return slot;
    };
    return {
        renderViewer: renderViewer
    };
};
exports["default"] = disableScrollPlugin;
