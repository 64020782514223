import React from "react";

const RoutesContentContainer = ({ location, children }) => {
  const styles = {
    maincontent: {
      height: "100vh",
      boxSizing: "border-box",
      width: "100%",
      paddingLeft: "300px",
      overflow: "auto",
      backgroundColor: "white",
    },
  };

  if (
    location.pathname !== "/" &&
    location.pathname !== "/en" &&
    location.pathname !== "/login" &&
    location.pathname !== "/personalize"
  )
    return (
      <div style={styles.maincontent}>
        <div className="ml-5 mr-5 h-100">{children}</div>
      </div>
    );
  else return <div styles={{ backgroundColor: "white" }}>{children}</div>;
};

export default RoutesContentContainer;
