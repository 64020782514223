import { useCallback, useContext, useEffect } from "react";

import AuthContext from "../context/authContext";
import UseFirestoreForBasicWordOperations from "./useFirestoreForBasicWordOperations";

const UseFirestoreForPdfListener = (
  pdfViewerLoaded,
  showPdfUrlRef,
  showPdfTitleRef
) => {
  const { user, loadingUser, collectedWords, masteredWords } =
    useContext(AuthContext);

  const {
    handleNewCollectedWord,
    handleNewMasteredWord,
    handleNewWordTranslation,
  } = UseFirestoreForBasicWordOperations();

  useEffect(() => {
    if (!pdfViewerLoaded) return;
    if (loadingUser) return;
    if (!user) {
      sendMessageToPdfjs("newUid", "");
      sendMessageToPdfjs("newUrl", showPdfUrlRef.current);
      sendMessageToPdfjs("newTitle", showPdfTitleRef.current);
    } else {
      sendMessageToPdfjs("newUid", user.uid);
      sendMessageToPdfjs("newUrl", showPdfUrlRef.current);
      sendMessageToPdfjs("title", showPdfTitleRef.current);
    }
  }, [user, loadingUser, pdfViewerLoaded]);

  useEffect(() => {
    if (!pdfViewerLoaded) return;
    if (loadingUser) return;
    if (!collectedWords) return;
    sendMessageToPdfjs("newCollectedWords", collectedWords);
    // console.log(collectedWords);
  }, [loadingUser, collectedWords, pdfViewerLoaded]);

  useEffect(() => {
    if (!pdfViewerLoaded) return;
    if (loadingUser) return;
    if (!masteredWords) return;
    sendMessageToPdfjs("newMasteredWords", masteredWords);
    // console.log(masteredWords);
  }, [loadingUser, masteredWords, pdfViewerLoaded]);

  const sendMessageToPdfjs = useCallback((command, data) => {
    const url = window.location.href;
    if (!document.getElementById("wordleap-iframe")) return;
    document.getElementById("wordleap-iframe").contentWindow.postMessage(
      {
        source: "wordleap-parent",
        command: command,
        data: data,
      },
      url
    );
  }, []);

  return {
    handleNewCollectedWord,
    handleNewMasteredWord,
    handleNewWordTranslation,
  };
};

export default UseFirestoreForPdfListener;
