import React, { useRef } from "react";
import SubtitleCard from "./subtitleCard";

const TranscriptionArea = ({ transcriptionArray, unknownWordArray }) => {
  const transcriptionRef = useRef();

  const styles = {
    subtitleArea: {
      height: "30px",
      overflow: "hidden",
      // backgroundColor: "orange",
    },
    transcriptionArea: {
      // height: transcriptionAreaHeight + "px",
      overflow: "hidden",
      alignItems: "flex-end",
      textAlign: "left",
    },
    transcription: {
      fontSize: "18px",
      lineHeight: "25px",
      color: "#373737",
      marginBottom: "0px",
      paddingBottom: "0px",
    },
    hr: {
      height: "24px",
      margin: "0px",
      border: "0px",
    },
    errorMessage: {
      maxWidth: "550px",
    },
  };

  const getDisplayTimeStamp = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  return (
    <div style={styles.transcriptionArea}>
      <div style={styles.transcription} ref={transcriptionRef}>
        {transcriptionArray.length !== 0 &&
          transcriptionArray.map((transcriptionObject, index) => {
            // console.log(transcriptionObject);
            // console.log(unknownWordArray);
            // console.log(index);
            const transcription = transcriptionObject.transcription;
            return (
              <div key={index}>
                <div>
                  {getDisplayTimeStamp(
                    transcriptionObject.milliSecondsSinceStart
                  )}
                </div>
                {unknownWordArray.length >= index + 1 &&
                  unknownWordArray[index].length !== 0 && (
                    <div
                      className="row mx-0 px-0 mt-0 mb-1"
                      style={styles.subtitleArea}
                    >
                      {unknownWordArray[index].map((wordObject, index) => (
                        <SubtitleCard
                          wordObject={wordObject}
                          key={index}
                          transcription={transcription}
                        />
                      ))}
                    </div>
                  )}
                <div key={transcriptionObject.timeStamp}>
                  {transcriptionObject.transcription}
                </div>
                <hr style={styles.hr} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TranscriptionArea;
