import { useCallback, useContext, useEffect } from "react";

import firebase from "../services/firebase";
import AuthContext from "../context/authContext";
import UseFirestoreNames from "../names/useFirestoreNames";
import useAnalytics from "../analytics/useAnalytics";

const UseFirestoreForBasicWordOperations = () => {
  const { logEvent } = useAnalytics();
  const { uidRef, collectedWordsRef, masteredWordsRef } =
    useContext(AuthContext);
  const db = firebase.firestore();
  const {
    userCollName,
    collectedWordsFieldName,
    masteredWordsFieldname,
    wordCollName,
  } = UseFirestoreNames();

  //   const setNewCollectedWords = useCallback((newCollectedWords) => {
  //     console.log("setNewCollectedWords");
  //     var data = {};
  //     data[collectedWordsFieldName] = newCollectedWords;
  //     db.collection(userCollName)
  //       .doc(uidRef.current)
  //       .update(data)
  //       .then(function () {
  //         console.log("Document successfully written!");
  //       })
  //       .catch(function (error) {
  //         console.error("Error writing document: ", error);
  //       });
  //   }, []);

  //   const setNewMasteredWords = useCallback((newMasteredWords) => {
  //     console.log("setNewMasteredWords");
  //     var data = {};
  //     data[masteredWordsFieldname] = newMasteredWords;
  //     db.collection(userCollName)
  //       .doc(uidRef.current)
  //       .update(data)
  //       .then(function () {
  //         console.log("Document successfully written!");
  //       })
  //       .catch(function (error) {
  //         console.error("Error writing document: ", error);
  //       });
  //   }, []);

  const setNewCollectedWordsAndNewMasterdWords = useCallback(
    (newCollectedWords, newMasteredWords) => {
      // console.log("setNewCollectedWordsAndNewMasterdWords");
      var data = {};
      data[masteredWordsFieldname] = newMasteredWords;
      data[collectedWordsFieldName] = newCollectedWords;
      // console.log(userCollName);
      // console.log(uidRef.current);
      // console.log(data);
      db.collection(userCollName)
        .doc(uidRef.current)
        .update(data)
        .then(function () {
          console.log("Document successfully written!");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    },
    [db]
  );

  const setNewWordHistory = useCallback((wordHistory) => {
    var word = wordHistory.word;
    //set doc name
    const currentDate = wordHistory.date;
    var history = {};
    history[currentDate] = { ...wordHistory };
    //get docRef
    const path = `${userCollName}/${uidRef.current}/${wordCollName}/${word}`;
    const docRef = db.doc(path);
    //set doc
    docRef.set({ history }, { merge: true }).catch(function (error) {
      console.error("Error writing document: ", error);
    });
    //Secondlly, set lasted edit date
    //set lasted edit date, url and sentence
    var lastEditData = { ...wordHistory };
    if (wordHistory.action === "collect") {
      lastEditData.status = "collected";
    }
    if (lastEditData.action === "master") {
      lastEditData.status = "mastered";
    }
    if (lastEditData.action === "editTranslation") {
      lastEditData.ifEditedTrans = true;
    }
    // console.log(lastEditData);
    //   console.log(lastEditData);
    docRef.update(lastEditData).catch(function (error) {
      console.error("Error writing document: ", error);
    });
  }, []);

  const handleNewCollectedWord = useCallback(
    (wordHistory) => {
      logEvent("collect-word", "collect-word-" + wordHistory.platform);
      var collectedWords = [...collectedWordsRef.current];
      var masteredWords = [...masteredWordsRef.current];
      //get newCollectedWords and newMasteredWords
      var word = wordHistory.word;
      var newCollectedWords, newMasteredWords;
      if (!collectedWords.includes(word)) {
        newCollectedWords = [word, ...collectedWords];
      } else {
        newCollectedWords = [...collectedWords];
      }
      if (masteredWords.includes(word)) {
        newMasteredWords = masteredWords.filter((masteredWord) => {
          return masteredWord !== word;
        });
      } else {
        newMasteredWords = [...masteredWords];
      }
      //update firebase
      setNewCollectedWordsAndNewMasterdWords(
        newCollectedWords,
        newMasteredWords
      );
      setNewWordHistory(wordHistory);
    },
    [setNewCollectedWordsAndNewMasterdWords, setNewWordHistory]
  );

  const handleNewMasteredWord = useCallback(
    (wordHistory) => {
      logEvent("master-word", "master-word-" + wordHistory.platform);
      var collectedWords = [...collectedWordsRef.current];
      var masteredWords = [...masteredWordsRef.current];
      //get newCollectedWords and newMasteredWords
      var word = wordHistory.word;
      var newCollectedWords, newMasteredWords;
      if (!masteredWords.includes(word)) {
        newMasteredWords = [word, ...masteredWords];
      } else {
        newMasteredWords = [...masteredWords];
      }
      if (collectedWords.includes(word)) {
        newCollectedWords = collectedWords.filter((collectedWord) => {
          return collectedWord !== word;
        });
      } else {
        newCollectedWords = [...collectedWords];
      }
      //update firebase
      setNewCollectedWordsAndNewMasterdWords(
        newCollectedWords,
        newMasteredWords
      );
      setNewWordHistory(wordHistory);
    },
    [setNewCollectedWordsAndNewMasterdWords, setNewWordHistory]
  );

  const handleNewWordTranslation = useCallback((wordHistory) => {
    setNewWordHistory(wordHistory);
  }, []);

  return {
    handleNewCollectedWord,
    handleNewMasteredWord,
    handleNewWordTranslation,
  };
};

export default UseFirestoreForBasicWordOperations;
