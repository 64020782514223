import $ from "jquery";

const UseMailChimp = () => {
  // const serverUrl = "http://127.0.0.1:8080/";
  const serverUrl = "https://wordly-server-hlxo6jmzoq-uc.a.run.app/";

  const addNewSignUpEmailToMailChimp = async (email) => {
    const currentURL = window.location.href;
    const settings = {
      async: true,
      crossDomain: true,
      url: serverUrl + "add-new-sign-up-email-to-mail-chimp",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": currentURL,
      },
      data: {
        email: email,
        tags: JSON.stringify(["sign-up-from-web"]),
      },
    };
    $.ajax(settings).done(function (response) {
      //   var responseObject = JSON.parse(response);
      console.log(response);
    });
  };

  return { addNewSignUpEmailToMailChimp };
};

export default UseMailChimp;
