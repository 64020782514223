import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import useSubtitle from "./useSubtitle";

const UseTranscription = (handleTranscriptionChange, title, initDate) => {
  const [transcriptionArray, setTranscriptionArray] = useState([]);
  const transcriptionArrayRef = useRef([]);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    unknownWordArray,
    unknownWordArrayRef,
    setUseSubtitleTranscript,
  } = useSubtitle(transcriptionArrayRef);

  const allTranscript = useRef("");
  const shouldStopRef = useRef(false);

  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = useMemo(() => new SpeechRecognition(), [
    SpeechRecognition,
  ]);
  recognition.continuous = true;
  recognition.interimResults = true;

  const startTranscription = useCallback(() => {
    var newStartTranscriptionTimeStamp = new Date();
    setErrorMessage("");
    shouldStopRef.current = false;
    recognition.start();
    lastStartTimeStampRef.current = newStartTranscriptionTimeStamp;
  }, [recognition]);

  const handlePauseTranscription = useCallback(async () => {
    shouldStopRef.current = true;
    console.log("manual pause transcription");
    recognition.stop();
  }, [recognition]);

  const lastStartTimeStampRef = useRef(null);
  const handleStartTranscription = useCallback(async () => {
    const lastStartTranscriptionTimeStamp = lastStartTimeStampRef.current;
    if (lastStartTranscriptionTimeStamp === null) {
      startTranscription();
      return;
    }
    var miliSeconds = calculateTimeSinceLastTranscriptionStart();
    if (miliSeconds < 1000) {
      console.log("transcription starts too quickly, possibly a loop");
      handlePauseTranscription();
    } else {
      startTranscription();
    }
  }, [startTranscription, handlePauseTranscription]);

  const calculateTimeSinceLastTranscriptionStart = () => {
    var newStartTranscriptionTimeStamp = new Date();
    const lastStartTranscriptionTimeStamp = lastStartTimeStampRef.current;
    var miliSeconds =
      newStartTranscriptionTimeStamp.getTime() -
      lastStartTranscriptionTimeStamp.getTime();
    // console.log("since last transcription start: " + miliSeconds);
    return miliSeconds;
  };

  //OnMount
  useEffect(() => {
    handleStartTranscription();
  }, [handleStartTranscription]);

  //OnUnmount
  useEffect(() => {
    return () => {
      handlePauseTranscription();
    };
  }, [handlePauseTranscription]);

  recognition.onstart = function () {
    setErrorMessage("");
    console.log("recognition started");
  };

  recognition.onerror = function (event) {
    console.log("recognition onerror");
    console.log(event);
    //If error is no speech, restart the recognition
    if (event.error === "no-speech") {
      console.log("error: no speech");
      shouldStopRef.current = false;
      return;
    }
    if (event.error === "aborted") {
      console.log("error: aborted");
      shouldStopRef.current = false;
      return;
    }
    //If error is anything else, then recognition should be stopped
    shouldStopRef.current = true;
    parseErrorEventData(event);
  };

  const parseErrorEventData = (event) => {
    if (event.error === "audio-capture") {
      console.log("error: audio-capture");
      setErrorMessage("There is an error regarding audio capture");
      return;
    }
    if (event.error === "not-allowed") {
      console.log("error: info_blocked ");
      setErrorMessage(
        "Wordleap can not access your microphone. Please try again after giving Wordleap permission to access your microphone."
      );
      return;
    }
    if (event.error === "network") {
      console.log("error: network ");
      setErrorMessage(
        "There has been an error with the network. This feature is currently unavailable in mainland China."
      );
      return;
    }
    setErrorMessage("There is an error for unknown reason");
  };

  const timeSinceLastStopRef = useRef(0);
  recognition.onend = function () {
    setTimeSinceLastStopRef();
    if (shouldStopRef.current === false) {
      handleStartTranscription();
    }
  };

  const setTimeSinceLastStopRef = () => {
    const currentTime = new Date();
    timeSinceLastStopRef.current =
      timeSinceLastStopRef.current +
      currentTime.getTime() -
      lastStartTimeStampRef.current;
    // console.log(timeSinceLastStopRef.current);
  };

  recognition.onresult = function (event) {
    // console.log(event);
    if (typeof event.results == "undefined") {
      console.log("got undefined result");
      return;
    }
    parseRecognitionResultData(event);
  };

  const parseRecognitionResultData = (event) => {
    // console.log(event);
    var interim_transcript = "";
    var final_transcript = "";
    var hasFinalResult = false;
    //set interim_transcript, final_transcript, all_transcript
    for (var i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        final_transcript = event.results[i][0].transcript;
        allTranscript.current = allTranscript.current + final_transcript;
        hasFinalResult = true;
      } else {
        interim_transcript += event.results[i][0].transcript;
        hasFinalResult = false;
      }
    }
    setDisplayTranscript(interim_transcript, final_transcript, hasFinalResult);
    setUseSubtitleTranscript(
      interim_transcript,
      final_transcript,
      hasFinalResult
    );
    handleTranscriptionChange();
  };

  const justHadFinalResultRef = useRef(false);
  const resultStartTimeStampRef = useRef();
  const setDisplayTranscript = (
    interim_transcript,
    final_transcript,
    hasFinalResult
  ) => {
    var currentTranscriptionArray = transcriptionArrayRef.current;
    switch (hasFinalResult) {
      case true:
        if (currentTranscriptionArray.length !== 0) {
          currentTranscriptionArray.pop();
        }
        currentTranscriptionArray.push({
          transcription: final_transcript,
          timeStamp: resultStartTimeStampRef.current,
          milliSecondsSinceStart: getTranscriptionTimeSinceStart(),
          isFinal: true,
        });
        justHadFinalResultRef.current = true;
        break;

      case false:
        if (
          currentTranscriptionArray.length === 0 ||
          justHadFinalResultRef.current === true
        ) {
          resultStartTimeStampRef.current = new Date();
        }
        if (
          currentTranscriptionArray.length !== 0 &&
          justHadFinalResultRef.current === false
        ) {
          currentTranscriptionArray.pop();
        }
        currentTranscriptionArray.push({
          transcription: interim_transcript,
          timeStamp: resultStartTimeStampRef.current,
          milliSecondsSinceStart: getTranscriptionTimeSinceStart(),
          isFinal: false,
        });
        justHadFinalResultRef.current = false;

        break;

      default:
        break;
    }

    transcriptionArrayRef.current = currentTranscriptionArray;
    setTranscriptionArray([...currentTranscriptionArray]);
    // if (hasFinalResult) console.log(transcriptionArrayRef.current);
    // console.log(transcriptionArrayRef.current);
  };

  const getTranscriptionTimeSinceStart = () => {
    let transcriptionTimeSinceStart =
      timeSinceLastStopRef.current +
      resultStartTimeStampRef.current.getTime() -
      lastStartTimeStampRef.current.getTime();
    // console.log(transcriptionTimeSinceStart);
    return transcriptionTimeSinceStart;
  };

  return {
    errorMessage,
    unknownWordArray,
    transcriptionArray,
    handlePauseTranscription,
    handleStartTranscription,
    unknownWordArrayRef,
    transcriptionArrayRef,
  };
};

export default UseTranscription;
