import React, { useState } from "react";

import useFilterAndSortWords from "./useFilterAndSortWords";

import WordCard from "./wordCard";
import Tips from "./tips";
import DropDownButton from "../../common/dropDownButton";
import HomePageContentTitle from "../homePageContentTitle";

const sortOptions = ["Latest", "A to Z"];
const filterOptions = ["Collected", "Mastered", "All"];

const WordListContent = () => {
  const [playingAudio, setPlayingAudio] = useState(false);

  const {
    sortState,
    filterState,
    setSortState,
    setFilterState,
    displayWords,
    wordDocsLoaded,
    wordDocs,
  } = useFilterAndSortWords();

  const handleSortSelect = (e) => {
    setSortState(e);
  };

  const handleFilterSelect = (e) => {
    setFilterState(e);
  };

  return (
    <React.Fragment>
      <HomePageContentTitle title={"Word List"} />

      <div className="mx-0">
        {wordDocsLoaded && wordDocs.length !== 0 && (
          <div className="row mx-0 mb-3">
            <DropDownButton
              title={sortState}
              options={sortOptions}
              onSelect={handleSortSelect}
            />
            <DropDownButton
              title={filterState}
              options={filterOptions}
              onSelect={handleFilterSelect}
            />
          </div>
        )}

        <div className="row mx-0">
          {wordDocsLoaded &&
            displayWords.length !== 0 &&
            displayWords.map((word) => (
              <WordCard
                wordDoc={word}
                key={word.word}
                playingAudio={playingAudio}
                setPlayingAudio={setPlayingAudio}
              />
            ))}
        </div>

        {wordDocsLoaded && displayWords.length <= 2 && (
          <React.Fragment>
            {displayWords.length === 0 && (
              <WordCard
                wordDoc={{
                  word:
                    filterState === "Uncollected"
                      ? "There is no uncollected word"
                      : "No word has been collected",
                }}
                noWordParagraph={
                  filterState === "Uncollected"
                    ? "You can view it here when you uncollect a word"
                    : "Start browsing to collect words. You can view it here once it is collected"
                }
                playingAudio={playingAudio}
                hasNoWord={true}
                setPlayingAudio={setPlayingAudio}
              />
            )}
            {/* <Tips /> */}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default WordListContent;
