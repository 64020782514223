import React from "react";
import useWindowDimensions from "../common/useWindowDimensions";

const ImgColumn = ({ img, imgAlt, imgHasShadow = true }) => {
  const { isMobile } = useWindowDimensions();
  return (
    <div
      className={
        isMobile
          ? "col-lg-6 col-md-6 col-sm-12 mb-4 d-flex align-items-center px-3"
          : "col-lg-6 col-md-6 col-sm-12 mb-5 d-flex align-items-center px-4"
      }
    >
      <img
        src={img}
        alt={imgAlt}
        className={
          imgHasShadow ? "rounded display-img shadow" : "rounded display-img "
        }
        style={{
          width: "100%",
          maxWidth: "700px",
          // width: "auto",
          // height: "auto",
        }}
        id="carousel-image"
      />
    </div>
  );
};

export default ImgColumn;
