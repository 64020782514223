import React, { useState, useRef, useEffect } from "react";
import { Worker } from "@react-pdf-viewer/core";

import HomePageContentTitle from "../homePageContentTitle";
import PdfListener from "./pdfListener";
import PdfViewer from "./pdfViewer";
import useFirestoreForPdf from "../../../firestore/useFirestoreForPdf";
import PdfCard from "./pdfCard";
import PdfUploadingStatusBar from "./pdfUploadingStatusBar";
import UploadPdfCard from "./uploadPdfCard";
import useAnalytics from "../../../analytics/useAnalytics";
import useFirestoreForOnboarding from "../../../firestore/useFirestoreForOnboarding";
import OnboardingHotspot from "../../onboarding/onboardingHotspot";

const PdfContent = () => {
  const {
    currentOnboarding,
    onboardingStepNames,
    handleFinishedUploadingPdf,
    handleFinishedOpenPdf,
  } = useFirestoreForOnboarding();
  const uploadPdfCardRef = useRef();
  const firstPdfCardRef = useRef();

  const { logEvent } = useAnalytics();
  const hiddenFileInput = useRef(null);
  const { uploadPdfFile, pdfDocs, pdfDocsLoaded } = useFirestoreForPdf();
  const [ifShowPdf, setIfShowPdf] = useState(false);
  const showPdfUrlRef = useRef(null);
  const showPdfTitleRef = useRef("");
  const showPdfIdRef = useRef("");
  const [ifUploadingPdf, setIfUploadingPdf] = useState(false);
  const [ifShowPdfUploadingStatusBar, setIfShowPdfUploadingStatusBar] =
    useState(false);
  const [uploadingPdfTitle, setUploadingPdfTitle] = useState("");

  // useEffect(() => {
  //   if (!pdfDocsLoaded) return;
  //   handlePdfCardClick(
  //     "https://firebasestorage.googleapis.com/v0/b/wordly-43984.appspot.com/o/users%2FnbGNbtLrhQU3cIDaegx8WJv5Pfk2%2Fpdfs%2F2a565a15-ccd0-4b0d-9678-1c0eafe4d285.pdf?alt=media&token=7fd5ea0f-d309-4993-8f85-d86681e12a79",
  //     " Vermeer Technologies (A): A Company is Born.pdf",
  //     "fada0391-497b-4dcd-8459-168ac11494e2"
  //   );
  // }, [pdfDocsLoaded]);

  const handlePDFUpload = () => {
    logEvent("upload-new-pdf", "upload-new-pdf");
    hiddenFileInput.current.click();
  };

  const handleFileChange = async (e) => {
    if (!e.target.files[0]) {
      // console.log("Please select PDF file");
      return;
    }
    if (e.target.files[0].type !== "application/pdf") {
      // console.log("Please select PDF file");
      return;
    }
    setIfUploadingPdf(true);
    setUploadingPdfTitle(e.target.files[0].name);
    setIfShowPdfUploadingStatusBar(true);
    //after uploading pdf file finished, then setIfUploadingPdf is false
    await uploadPdfFile(e.target.files[0]);
    handleFinishedUploadingPdf();
    logEvent("upload-new-pdf-success", "upload-new-pdf-success");
    setIfUploadingPdf(false);
  };

  const handlePdfCardClick = (url, title, id) => {
    handleFinishedOpenPdf();
    logEvent("open-pdf", "open-pdf");
    setIfShowPdf(true);
    showPdfUrlRef.current = url;
    showPdfTitleRef.current = title;
    showPdfIdRef.current = id;
    // console.log(url);
    // console.log(title);
    // console.log(id);
  };

  const handleClosePdfViewer = () => {
    logEvent("close-pdf-viewer", "close-pdf-viewer");
    setIfShowPdf(false);
    showPdfUrlRef.current = null;
    showPdfTitleRef.current = "";
    showPdfIdRef.current = "";
  };

  const handleUploadingStatusBarClose = () => {
    logEvent("close-pdf-upload-status-bar", "close-pdf-upload-status-bar");
    setIfShowPdfUploadingStatusBar(false);
  };

  return (
    <PdfListener
      onClosePdfViewer={handleClosePdfViewer}
      showPdfUrlRef={showPdfUrlRef}
      showPdfTitleRef={showPdfTitleRef}
      showPdfIdRef={showPdfIdRef}
      ifShowPdf={ifShowPdf}
    >
      <React.Fragment>
        {pdfDocsLoaded &&
          currentOnboarding === onboardingStepNames.uploadNewPdf && (
            <OnboardingHotspot uploadPdfCardRef={uploadPdfCardRef} />
          )}

        {pdfDocsLoaded &&
          pdfDocs.length >= 1 &&
          currentOnboarding === onboardingStepNames.openPdfFile && (
            <OnboardingHotspot firstPdfCardRef={firstPdfCardRef} />
          )}
        <HomePageContentTitle title="PDF">
          <div className="mx-0">
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {/* <button className="btn btn-primary" onClick={handlePDFUpload}>
              Upload PDF
            </button> */}
          </div>
        </HomePageContentTitle>

        {/* {pdfDocsLoaded && pdfDocs.length === 0 && (
          <div className="mx-0">
            <p>
              Welcome to WordLeap
              <br /> Please get started by uploading your PDF
            </p>
          </div>
        )} */}

        {pdfDocsLoaded && (
          <div className="row">
            <UploadPdfCard
              onPdfUploadButtonClick={handlePDFUpload}
              uploadPdfCardRef={uploadPdfCardRef}
            />
            {pdfDocs.length !== 0 && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                {pdfDocs.map((pdfDoc, index) => (
                  <PdfCard
                    key={pdfDoc.date}
                    date={pdfDoc.date}
                    title={pdfDoc.title}
                    url={pdfDoc.url}
                    id={pdfDoc.id}
                    onPdfCardClick={handlePdfCardClick}
                    pdfCardRef={(ref) => {
                      if (index === 0) firstPdfCardRef.current = ref;
                    }}
                  />
                ))}
              </Worker>
            )}
          </div>
        )}

        {ifShowPdf && <PdfViewer src={showPdfUrlRef.current} />}

        {ifShowPdfUploadingStatusBar && (
          <PdfUploadingStatusBar
            ifUploadingPdf={ifUploadingPdf}
            uploadingPdfTitle={uploadingPdfTitle}
            onCloseButtonClick={handleUploadingStatusBarClose}
          />
        )}
      </React.Fragment>
    </PdfListener>
  );
};

export default PdfContent;
