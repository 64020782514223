import React from "react";

const navigationMenuItem = ({
  buttonText,
  onClick,
  activeIconSrc,
  inactiveIconSrc,
  isActive,
}) => {
  const styles = {
    activeButtonStyle: {
      width: "300px",
      height: "50px",
      fontSize: "17px",
      marginLeft: "-50px",
      paddingLeft: "60px",
      fontWeight: "500",
      color: "#33A747",
      backgroundColor: "#DCF8E1",
    },
    inactiveButtonStyle: {
      width: "300px",
      height: "50px",
      fontSize: "17px",
      marginLeft: "-50px",
      paddingLeft: "60px",
      fontWeight: "400",
      color: "#4C4C4C",
      backgroundColor: "white",
    },
    buttonText: {
      margin: "0",
      paddingBottom: "0px",
    },
  };
  return (
    <div style={{ backgroundColor: "transparant", marginBottom: "4px" }}>
      <button
        className="btn  d-flex align-items-center"
        style={isActive ? styles.activeButtonStyle : styles.inactiveButtonStyle}
        onClick={onClick}
      >
        <img
          src={isActive ? activeIconSrc : inactiveIconSrc}
          alt="icon"
          style={{ height: 20, marginRight: 16 }}
        />
        <p style={styles.buttonText}>{buttonText}</p>
      </button>
    </div>
  );
};

export default navigationMenuItem;
