import React, { useState, useCallback } from "react";
import RadioButtonGroup from "./radioButtonGroup";
import DropDownButton from "../common/dropDownButton";
import MultipleRadioButtonGroups from "./multipleRadioButtonGroups";

const Selections = ({ selectionType, options, answer, setAnswer }) => {
  const [dropDownButtonTitle, setDropDownButtonTitle] =
    useState("Select an option");

  const handleDropDownSelect = (e) => {
    setDropDownButtonTitle(e);
    setAnswer(e);
  };

  const handleMultipleRadioButtonAnswerChange = useCallback(
    (newAnswer) => {
      setAnswer(newAnswer);
    },
    [setAnswer]
  );

  switch (selectionType) {
    case "RadioButtonGroup":
      return (
        <RadioButtonGroup
          options={options}
          answer={answer}
          setAnswer={setAnswer}
          ifWrap={false}
        />
      );

    case "RadioButtonGroupWrap":
      return (
        <RadioButtonGroup
          options={options}
          answer={answer}
          setAnswer={setAnswer}
          ifWrap={true}
        />
      );

    case "DropDownMenu":
      return (
        <div className="mb-2">
          <DropDownButton
            title={dropDownButtonTitle}
            options={options}
            onSelect={handleDropDownSelect}
            setDropDownButtonTitle={setDropDownButtonTitle}
          />
        </div>
      );

    case "MultipleRadioButtonGroups":
      return (
        <MultipleRadioButtonGroups
          options={options}
          onAnswerChange={handleMultipleRadioButtonAnswerChange}
          answer={answer}
        />
      );

    default:
      return <div>Program Error</div>;
  }
};

export default Selections;
