import React, { useState, useEffect, useRef, useCallback } from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";

import useAuth from "../auth/useAuth";

//Import Img
import logoWithTitle from "../assets/logoWithTitle.png";
import backgroundImage from "../assets/backgroundImage.png";
import wordleapYoutubeSubtitle from "../assets/wordleapYoutubeSubtitle.gif";
import googleIcon from "../assets/googleIcon.svg";

import pdfReaderGif from "../assets/pdfReader.gif";

import FullScreenPageWrapper from "../components/common/fullScreenPageWrapper";
import useAnalytics from "../analytics/useAnalytics";
import useFilterAndSortWords from "../components/home/wordList/useFilterAndSortWords";

function LogInPage() {
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const [mode, setMode] = useState("SIGN_UP");
  const [error, setError] = useState("");

  const { signUpWithPassword, signInWithPassword, signInWithGoogle } =
    useAuth();

  useEffect(() => {
    if (mode === "SIGN_UP") {
      renderSignUp();
    }
    if (mode === "SIGN_IN") {
      renderSignIn();
    }
  }, [mode]);

  const renderSignUp = () => {
    $("#primary-form-button").text(t("sign-up"));
    $("#secondary-form-button").text(t("sign-in-instead"));
  };

  const renderSignIn = () => {
    $("#primary-form-button").text(t("sign-in"));
    $("#secondary-form-button").text(t("sign-up-instead"));
  };

  const handlePrimaryFormButtonClick = async () => {
    var password = $("#inputPassword").val();
    var email = $("#inputEmail").val();

    if (mode === "SIGN_UP") {
      logEvent("sign-up", t("sign-up-label"));
      try {
        await signUpWithPassword(email, password);
      } catch (error) {
        setError(error.message);
      }
    } else {
      logEvent("sign-in", t("sign-in-label"));
      try {
        await signInWithPassword(email, password);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleSignInWithGoogle = async () => {
    await signInWithGoogle();
  };

  const handleSecondaryFormButtonClick = () => {
    if (mode === "SIGN_UP") {
      setMode("SIGN_IN");
      logEvent("sign-in-instead", t("sign-in-instead-label"));
    } else {
      setMode("SIGN_UP");
      logEvent("sign-up-instead", t("sign-up-instead-label"));
    }
  };

  const styles = {
    fullscreen: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0px",
      left: "0px",
      // backgroundColor: "coral",
      backgroundSize: "cover",
    },
    leftPanel: {
      backgroundImage: `url(${backgroundImage})`,
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      backgroundSize: "cover",
    },
    rightPanel: {
      backgroundColor: "white",
    },
    logoWithTitle: {
      width: 140,
    },
    title: {
      color: "white",
      fontSize: "2.3rem",
      fontWeight: "500",
    },
    titleRow: {
      marginTop: "0rem",
      marginLeft: "0rem",
    },
    displayImage: {
      marginTop: "30px",
      display: "inline-block",
      maxWidth: "100%",
    },
    displayRow: {
      marginTop: "3rem",
      marginLeft: "4rem",
    },
    controllerRow: {
      marginTop: "0.5rem",
    },
    circleButton: {
      border: 0,
      outline: 0,
    },
    circleButtonImg: {
      height: 8,
    },
    googleIcon: { position: "absolute", top: "0%" },
  };

  return (
    <FullScreenPageWrapper>
      <div className="col-7" style={styles.leftPanel}>
        <div className="row mt-4 ml-1" style={{ position: "absolute" }}>
          <div className="col">
            <img src={logoWithTitle} alt="Logo" style={styles.logoWithTitle} />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <div
            className="w-55"
            style={{ maxWidth: "500px", marginRight: "40px" }}
          >
            {/* <span style={styles.title}>{t("homepage-title-1")}</span> */}
            <img
              src={pdfReaderGif}
              alt="pdfReaderGif"
              style={styles.displayImage}
              className=" rounded"
              id="carousel-image"
            />
          </div>
        </div>
      </div>
      <div
        className="col-5 d-flex align-items-center justify-content-center"
        style={styles.rightPanel}
      >
        <div className="w-80" style={{ width: "450px" }}>
          <div className="row mt-4 ml-1">
            <div className="col">
              <h3 style={{ marginBottom: "12px" }}>
                {t("welcome-to-wordleap")}
              </h3>
              {mode === "SIGN_UP" ? (
                <p>{t("start-by-signing-up")}</p>
              ) : (
                <p>{t("start-by-signing-in")}</p>
              )}
            </div>
          </div>

          <div className="row mt-4 ml-1">
            <div className="col">
              <label htmlFor="inputEmail" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                id="inputEmail"
                className="form-control mb-4"
                placeholder="Email address"
                required=""
                autoFocus=""
              />
              <label htmlFor="inputPassword" className="sr-only">
                Password
              </label>
              <input
                type="password"
                id="inputPassword"
                className="form-control"
                placeholder="Password"
                required=""
              />

              {error !== "" && (
                <div className="alert alert-danger mt-4">{error}</div>
              )}
            </div>
          </div>

          <div className="row mt-4 ml-1">
            <div className="col">
              <button
                className="btn w-100 btn-primary mb-3"
                id="primary-form-button"
                onClick={handlePrimaryFormButtonClick}
              >
                {t("sign-up")}
              </button>
              <button
                className="btn w-100 btn-outline-dark mb-3"
                onClick={handleSignInWithGoogle}
              >
                <img src={googleIcon} style={styles.googleIcon} />
                Continue with Google
              </button>
              <button
                className="btn w-100 btn-link mb-3"
                id="secondary-form-button"
                onClick={handleSecondaryFormButtonClick}
                style={styles.googleButton}
              >
                {t("sign-in-instead")}
              </button>
              {mode === "SIGN_UP" ? (
                <p className="small text-center text-secondary">
                  By signing up, you agree to our{" "}
                  <a href={"https://wordly-privacy-policy.herokuapp.com/"}>
                    privacy policy
                  </a>
                </p>
              ) : (
                <p className="small text-center text-secondary"> </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </FullScreenPageWrapper>
  );
}

export default LogInPage;
