import React from "react";
import { Switch } from "react-router-dom";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

import AuthProvider from "./context/authProvider";
import PrivateRoute from "./route/privateRoute";
// import PdfListener from "./components/home/pdf/pdfListener";

const TRACKING_ID = "UA-195431385-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const FACEBOOK_PIXEL_ID = "349838160146475";
// const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
ReactPixel.init(FACEBOOK_PIXEL_ID, options);
ReactPixel.pageView(); // For tracking page view

function App() {
  return (
    <AuthProvider>
      {/* <PdfListener> */}
      <Switch>
        <PrivateRoute />
      </Switch>
      {/* </PdfListener> */}
    </AuthProvider>
  );
}

export default App;
