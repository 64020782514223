import React, { useState, useEffect } from "react";
import useFirestoreForOnboarding from "../../firestore/useFirestoreForOnboarding";
import useAnalytics from "../../analytics/useAnalytics";

import hotspotIcon from "../../assets/hotspotIcon.svg";

const OnboardingHotspot = ({ uploadPdfCardRef, firstPdfCardRef }) => {
  const { logEvent } = useAnalytics();
  const {
    currentOnboarding,
    onboardingStepNames,
    onboardingHotspotContent,
    handleFinishedUploadingPdf,
    handleFinishedOpenPdf,
  } = useFirestoreForOnboarding();
  const [ifShow, setIfShow] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    switch (currentOnboarding) {
      case onboardingStepNames.uploadNewPdf:
        setTitle(onboardingHotspotContent.uploadNewPdf.title);
        setDescription(onboardingHotspotContent.uploadNewPdf.description);
        setHotspotLocation(uploadPdfCardRef);
        setIfShow(true);
        break;
      case onboardingStepNames.openPdfFile:
        setTitle(onboardingHotspotContent.openPdfFile.title);
        setDescription(onboardingHotspotContent.openPdfFile.description);
        setHotspotLocation(firstPdfCardRef);
        setIfShow(true);
        break;
      default:
        break;
    }
  }, [currentOnboarding, window]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      switch (currentOnboarding) {
        case onboardingStepNames.uploadNewPdf:
          setHotspotLocation(uploadPdfCardRef);
          break;
        case onboardingStepNames.openPdfFile:
          setHotspotLocation(firstPdfCardRef);
          break;
        default:
          break;
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const setHotspotLocation = (ref) => {
    const hotspotHeight = 114;
    const { x, width, y, height } = ref.current.getBoundingClientRect();
    switch (currentOnboarding) {
      case onboardingStepNames.uploadNewPdf:
        setTop(y + 0.5 * height - 0.5 * hotspotHeight - 10);
        setLeft(x + width - 10);
        break;
      case onboardingStepNames.openPdfFile:
        const paddingLeft = 24;
        setTop(y + 0.5 * height - 0.5 * hotspotHeight - 10);
        setLeft(x + width - 13 - paddingLeft);
        break;
      default:
        break;
    }
  };

  const handleGotIt = () => {
    switch (currentOnboarding) {
      case onboardingStepNames.uploadNewPdf:
        handleFinishedUploadingPdf();
        setIfShow(false);
        logEvent("got-it", "got-it-upload-first-pdf");
        break;
      case onboardingStepNames.openPdfFile:
        handleFinishedOpenPdf();
        setIfShow(false);
        logEvent("got-it", "got-it-open-pdf");
        break;
      default:
        break;
    }
  };

  const styles = {
    container: {
      position: "absolute",
      zIndex: "100",
      top: top + "px",
      left: left + "px",
      hidden: ifShow ? false : true,
    },
    img: { height: "22px" },
    card: {
      width: "280px",
      border: "1px solid rgb(51, 167, 71)",
      borderRadius: "5px",
      paddingLeft: "12px",
      paddingRight: "8px",
      paddingTop: "14px",
      paddingBottom: "4px",
      marginLeft: "18px",
      backgroundColor: "white",
    },
    title: {
      fontSize: "14px",
      fontWeight: "700",
      color: "rgb(76,76,76)",
      marginBottom: "6px",
    },
    description: {
      fontSize: "14px",
      color: "rgb(76,76,76)",
      lineHeight: "18px",
    },
    button: {
      fontSize: "14px",
      color: "rgb(51, 167, 71)",
    },
  };

  return (
    <div style={styles.container}>
      <div className="d-flex justify-content-start align-items-center">
        <div>
          <img src={hotspotIcon} style={styles.img} />
        </div>
        <div className="triangle" style={styles.card}>
          <div className="non-select-text" style={styles.title}>
            {title}
          </div>
          <div className="non-select-text" style={styles.description}>
            {description}
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-sm btn-light"
              style={styles.button}
              onClick={handleGotIt}
            >
              Got it!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingHotspot;
