import React from "react";

import logo from "../../assets/logo.svg";

import PopupContainer from "../common/popupContainer";

const WelcomePopup = ({ onSkipPersonalization, onStartPersonalization }) => {
  const styles = {
    container: {
      backgroundColor: "white",
      width: "390px",
      borderRadius: "10px",
    },
    logo: { width: "100px" },
    description: {
      fontSize: "15px",
      lineHeight: "17px",
      marginBottom: "32px",
      width: "310px",
      fontWeight: "400",
    },
  };

  return (
    <PopupContainer backgroundColor="rgba(40, 40, 40, 0.3)">
      <div style={styles.container}>
        <div
          id="logo-row"
          className=" mx-4 d-flex justify-content-center"
          style={{ marginTop: "60px" }}
        >
          <img src={logo} style={styles.logo} />
        </div>
        <div id="welcome-row" className="mx-4" style={{ marginTop: "26px" }}>
          <div className="w-100 d-flex justify-content-center">
            <div className="h3">Welcome to WordLeap</div>
          </div>
        </div>
        <div
          id="description-row"
          className=" mx-4"
          style={{ marginTop: "4px" }}
        >
          <div className="w-100 d-flex justify-content-center">
            <div style={styles.description} className="text-center">
              Your answers to the next few questions will help us provide better
              word prediction and translation for you
            </div>
          </div>
        </div>
        <div
          id="button-row"
          className="mx-4 mt-3"
          style={{ marginBottom: "36px" }}
        >
          <button
            className="btn w-100 btn-primary mb-3"
            onClick={onStartPersonalization}
          >
            Start
          </button>
          <button
            className="btn w-100 btn-light btn-grey-link"
            onClick={onSkipPersonalization}
          >
            Skip Personalization
          </button>
        </div>
      </div>
    </PopupContainer>
  );
};

export default WelcomePopup;
