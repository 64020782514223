import React, { useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import useAnalytics from "../../analytics/useAnalytics";

const FirstVisibleSensor = ({ children, label }) => {
  const ifFirstVisibleRef = useRef(true);
  const { logEvent } = useAnalytics();

  const handleVisibleChange = (isVisible) => {
    if (ifFirstVisibleRef.current && isVisible) {
      logEvent("first-visible", label);
      ifFirstVisibleRef.current = false;
    }
  };

  return (
    <React.Fragment>
      <VisibilitySensor onChange={handleVisibleChange}>
        {children}
      </VisibilitySensor>
    </React.Fragment>
  );
};

export default FirstVisibleSensor;
