import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import TranslationEN from "./locales/translationEN.json";
import TranslationZN from "./locales/translationZN.json";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: { translations: TranslationEN },
    zn: { translations: TranslationZN },
  },
  lng: "zn",
  fallbackLng: "zn",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
