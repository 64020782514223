import React from "react";

const SubtitleCard = ({ wordObject, transcription }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const styles = {
    word: {
      marginRight: "4px",
    },
    translation: {},
    translationContainer: {
      height: "30px",
      marginBottom: "10px",
      marginRight: "10px",
      paddingLeft: "10px",
      paddingRight: "10px",
      fontSize: "17px",
      backgroundColor: "white",
      border: "1px solid #33A747",
      borderRadius: "6px",
    },
  };

  if (
    transcription.toLowerCase().trim().split(" ").includes(wordObject.keyword)
  )
    return (
      <div
        className="d-flex align-items-center "
        style={styles.translationContainer}
      >
        <span style={styles.word}>
          {capitalizeFirstLetter(wordObject.keyword)}
        </span>
        <span style={styles.translation}>{wordObject.translation}</span>
      </div>
    );
  else return <React.Fragment />;
};

export default SubtitleCard;
