import React from "react";

const HomePageContentTitle = ({ title, children }) => {
  return (
    <div className="mb-4 pt-5 d-flex justify-content-between">
      <h2 style={{ color: "#373737" }}>{title}</h2>
      {children}
    </div>
  );
};

export default HomePageContentTitle;
