import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import FirstVisibleSensor from "../common/firstVisibleSensor";
import { useTranslation } from "react-i18next";

import useAnalytics from "../../analytics/useAnalytics";
import { AiOutlineRight } from "react-icons/ai";
import { IconContext } from "react-icons";
import TryNowButton from "./tryNowButton";

const BottonFormSection = ({
  onButtonVisibleChange,
  status,
  message,
  onValidated,
  ifRequested,
  onIfRequestedChange,
  ifShowEmailForm = false,
  onBottomTryNow,
}) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { logEvent } = useAnalytics();
  const { t } = useTranslation();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const submit = () => {
    logEvent("sign-up-for-early-access-bottom", email);
    onValidated({
      EMAIL: email,
    });
  };

  useEffect(() => {
    if (status === "success") {
      onIfRequestedChange(true);
    }
  }, [status, onIfRequestedChange]);

  useEffect(() => {
    if (!message) return;
    if (message === "0 - Please enter a value") {
      setErrorMessage(t("mailchimp-error-no-value"));
    } else if (message.includes("is already subscribed to list WordLeap")) {
      setErrorMessage(t("mailchimp-error-already-subscribed"));
    } else {
      setErrorMessage(t("mailchimp-error-not-email-form"));
    }
  }, [message, t]);

  return (
    <React.Fragment>
      <div className="col-12 mb-1 d-flex justify-content-center">
        <FirstVisibleSensor
          label={t("bottom-form-title-first-visible-event-label")}
        >
          <h1
            className="font-weight-bold mb-4 text-center"
            style={{ lineHeight: 1.25, color: "#FFFFFF" }}
          >
            {t("bottom-form-title")}
          </h1>
        </FirstVisibleSensor>
      </div>
      <div className="col-12 mb-5 d-flex justify-content-center">
        <div
          style={{
            width: "95%",
            maxWidth: "550px",
            color: "#CDCDCD",
            textAlign: "center",
          }}
        >
          {t("bottom-form-description-for-try-now")}
        </div>
      </div>

      {!ifShowEmailForm && (
        <div className="col-12 mb-3 d-flex justify-content-center">
          <TryNowButton width={"250px"} onTryNow={onBottomTryNow} />
        </div>
      )}

      {ifShowEmailForm && status !== "success" && !ifRequested && (
        <React.Fragment>
          <div className="col-12 mb-3 d-flex justify-content-center">
            <div
              style={{
                width: "90%",
                maxWidth: "500px",
              }}
              className="input-group"
            >
              <input
                type="email"
                id="inputEmail"
                className="form-control mb-1"
                placeholder="Email"
                value={email}
                required=""
                autoFocus=""
                style={{ border: "1px solid rgb(106,201,121)", height: "43px" }}
                onChange={handleEmailChange}
              />
              <VisibilitySensor onChange={onButtonVisibleChange}>
                <div className="input-group-append" style={{}}>
                  <button
                    className="btn btn-primary"
                    style={{ minWidth: "130px", height: "43px" }}
                    onClick={submit}
                    disabled={status === "sending"}
                  >
                    {status === "sending" ? "Sending" : t("get-early-access")}
                    <IconContext.Provider
                      value={{
                        style: {
                          verticalAlign: "middle",
                          marginBottom: "2px",
                        },
                      }}
                    >
                      <AiOutlineRight />
                    </IconContext.Provider>
                  </button>
                </div>
              </VisibilitySensor>
            </div>
          </div>

          {ifShowEmailForm && status === "error" && (
            <div className="col-12 mb-0 d-flex justify-content-center">
              <div
                style={{
                  maxWidth: "500px",
                  color: "red",
                  width: "90%",
                }}
              >
                {/* {message.includes("is already subscribed to list WordLeap")
                  ? "This email has already been subscribed to WordLeap "
                  : message.startsWith("0 - ")
                  ? message.slice(3)
                  : message} */}
                {errorMessage !== "" && errorMessage}
              </div>
            </div>
          )}
        </React.Fragment>
      )}

      {ifShowEmailForm && (status === "success" || ifRequested) && (
        <React.Fragment>
          <div className="col-12 mb-0 d-flex justify-content-center">
            <button
              className="btn btn-primary mt-0 mb-2"
              style={{
                height: 40,
                maxWidth: "700px",
                width: "90%",
              }}
              disabled={true}
            >
              Thank you for signing up!
            </button>
          </div>
          <div
            className="col-12 mt-2 mb-0 d-flex justify-content-center"
            style={{
              color: "white",
            }}
          >
            You will receive our welcome email shortly
          </div>
          <div
            className="col-12 mb-0 d-flex justify-content-center"
            style={{ height: 40 }}
          ></div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BottonFormSection;
