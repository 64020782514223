import React from "react";
import { useTranslation } from "react-i18next";

import { AiOutlineDown } from "react-icons/ai";

const ScrollForMoreIndication = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        position: "absolute",
        left: "0px",
        bottom: "30px",
        height: "10px",
        width: "100%",
        textAlign: "center",
        color: "rgb(140, 140, 140)",
      }}
    >
      <AiOutlineDown /> {t("scroll-for-more")}
    </div>
  );
};

export default ScrollForMoreIndication;
