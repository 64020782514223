import React from "react";
import Lottie from "lottie-react";
import workingAnimation from "../../assets/workingAnimation.json";

const NoSupportScreen = ({ onMoreNativeLanguage }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
        margin: "0px",
        backgroundColor: "rgba(248, 248, 248, 0.85)",
        zIndex: "1000000",
      }}
    >
      <div>
        <Lottie animationData={workingAnimation} style={{ height: "200px" }} />
        <div className="d-flex justify-content-center">
          <h5
            style={{
              width: "500px",
              textAlign: "center",
              fontWeight: "600",
              lineHeight: "25px",
            }}
          >
            See You Soon!
          </h5>
        </div>
        <div className="d-flex justify-content-center">
          <p
            style={{
              width: "500px",
              textAlign: "center",
              fontWeight: "400",
              lineHeight: "25px",
            }}
          >
            We are sorry that your native language is not currently supported.
            Our engineering team is working very hard to support more languages.
            We will send you an email when it is ready.
          </p>
        </div>

        <div>
          <div className="w-100 d-flex justify-content-center">
            <button
              className="btn btn-md btn-light mb-1"
              style={{ color: "#7a7a7a" }}
              onClick={onMoreNativeLanguage}
            >
              I have more than 1 native language
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoSupportScreen;
