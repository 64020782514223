import { useContext, useEffect, useCallback } from "react";

import firebase from "../services/firebase";
import AuthContext from "../context/authContext";
import UseFirestoreNames from "../names/useFirestoreNames";

const UseFirestoreForPdfAnnotations = (
  pdfViewerLoaded,
  showPdfIdRef,
  ifShowPdf
) => {
  const { userCollName, pdfAnnotationCollName } = UseFirestoreNames();
  const { user, uidRef } = useContext(AuthContext);
  const db = firebase.firestore();

  const setEmptyDoc = useCallback(() => {
    const firestorePath = `${userCollName}/${uidRef.current}/${pdfAnnotationCollName}/${showPdfIdRef.current}`;
    const docRef = db.doc(firestorePath);
    const date = new Date().toISOString();
    const data = {};
    docRef
      .set(data)
      .then(() => {
        console.log("Success Create Pdf Doc " + date);
      })
      .catch((error) => {
        console.log("Error Create Pdf Doc " + date);
        throw error;
      });
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!ifShowPdf) return;
    if (!pdfViewerLoaded) return;
    db.collection(userCollName)
      .doc(user.uid)
      .collection(pdfAnnotationCollName)
      .doc(showPdfIdRef.current)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const pdfAnnotations = doc.data();
          sendMessageToPdfjs("pdfAnnotationsInit", pdfAnnotations);
        } else {
          sendMessageToPdfjs("pdfAnnotationsInit", null);
          setEmptyDoc();
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [user, ifShowPdf, pdfViewerLoaded, setEmptyDoc]);

  const handleNewAnnotation = async (annotation) => {
    const firestorePath = `${userCollName}/${uidRef.current}/${pdfAnnotationCollName}/${showPdfIdRef.current}`;
    const docRef = db.doc(firestorePath);
    const annotationId = annotation.id;
    const data = {};
    data[annotationId] = annotation;
    await docRef
      .update(data)
      .then(() => {
        const date = new Date().toISOString();
        console.log("Success Create Pdf Doc " + date);
      })
      .catch((error) => {
        console.log(error);
        const date = new Date().toISOString();
        console.log("Error Create Pdf Doc " + date);
        throw error;
      });
  };

  const sendMessageToPdfjs = useCallback((command, data) => {
    const url = window.location.href;
    if (!document.getElementById("wordleap-iframe")) return;
    document.getElementById("wordleap-iframe").contentWindow.postMessage(
      {
        source: "wordleap-parent",
        command: command,
        data: data,
      },
      url
    );
  }, []);

  return { handleNewAnnotation };
};

export default UseFirestoreForPdfAnnotations;
