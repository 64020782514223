import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import firebase from "../services/firebase";
import AuthContext from "../context/authContext";
import UseFirestoreNames from "../names/useFirestoreNames";

const UseFirebaseForPdf = () => {
  const [pdfDocs, setPdfDocs] = useState(null);
  const [pdfDocsLoaded, setPdfDocsLoaded] = useState(false);
  const { userCollName, pdfCollName } = UseFirestoreNames();
  const pdfIdsRef = useRef([]);

  const { uidRef, user } = useContext(AuthContext);
  const db = firebase.firestore();
  const fireStorage = firebase.storage();

  const addPdfId = useCallback(async (pdfDoc) => {
    if (pdfDoc.hasOwnProperty("id")) {
      pdfIdsRef.current.push(pdfDoc.id);
      return;
    }
    var newId = uuidv4();
    while (pdfIdsRef.current.includes(newId)) {
      newId = uuidv4();
    }
    const firestorePath = `${userCollName}/${uidRef.current}/${pdfCollName}/${pdfDoc.date}`;
    const docRef = db.doc(firestorePath);
    await docRef
      .update({
        id: newId,
      })
      .then(() => {
        const date = new Date().toISOString();
        console.log("Success Create Pdf Doc " + date);
      })
      .catch((error) => {
        const date = new Date().toISOString();
        console.log("Error Create Pdf Doc " + date);
        throw error;
      });
  }, []);

  useEffect(() => {
    if (!user) return;
    db.collection(userCollName)
      .doc(user.uid)
      .collection(pdfCollName)
      .onSnapshot(
        (querySnapshot) => {
          var pdfDocs = [];
          pdfIdsRef.current = [];
          querySnapshot.forEach(function (doc) {
            var pdfDoc = doc.data();
            addPdfId(pdfDoc);
            pdfDocs.unshift(pdfDoc);
          });
          setPdfDocs(pdfDocs);
          setPdfDocsLoaded(true);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [user, addPdfId]);

  const getPdfTitle = useCallback(
    (url) => {
      if (!pdfDocs) return;
      var title = "";
      pdfDocs.forEach((pdfDoc) => {
        if (pdfDoc.url === url) {
          title = pdfDoc.title;
        }
      });
      return title;
    },
    [pdfDocs]
  );

  const uploadPdfFile = async (file) => {
    //upload pdf to firebase storage
    const firebaseStoragePath = `${userCollName}/${
      uidRef.current
    }/${pdfCollName}/${uuidv4()}.pdf`;
    const pdfRef = fireStorage.ref(firebaseStoragePath);
    await pdfRef.put(file);
    //get pdf url from firebase storage
    const pdfUrl = await pdfRef.getDownloadURL();
    //get new id
    var newId = uuidv4();
    while (pdfIdsRef.current.includes(newId)) {
      newId = uuidv4();
    }
    //save pdf url to firestore
    const date = new Date().toISOString();
    const firestorePath = `${userCollName}/${uidRef.current}/${pdfCollName}/${date}`;
    const docRef = db.doc(firestorePath);
    await docRef
      .set({
        uid: uidRef.current,
        title: file.name,
        date: date,
        url: pdfUrl,
        id: newId,
      })
      .then(() => {
        console.log("Success Create Pdf Doc " + date);
      })
      .catch((error) => {
        console.log("Error Create Pdf Doc " + date);
        throw error;
      });
  };

  return { pdfDocs, pdfDocsLoaded, uploadPdfFile, getPdfTitle };
};

export default UseFirebaseForPdf;
