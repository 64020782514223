import React, { useEffect, useState } from "react";
// import Lottie from "lottie-react";
// import loadingAnimation from "../../assets/loadingAnimation.json";

const UploadingAnimation = ({ onUploadingAnimationFinished }) => {
  const [step, setStep] = useState(0);
  const stepNumber = 4;
  // const loadingText = [
  //   "Uploading your information to a secure server hosted by Google",
  //   "Creating personalized vocabulary prediction model based on your selections",
  //   "Your personalized vocabulary prediction model is about to be ready",
  //   "Let's start the journey together to read and listen to English effortlessly",
  // ];

  useEffect(() => {
    if (step < stepNumber) {
      let waitTime;
      switch (step) {
        // case 0:
        //   waitTime = Math.random() * 2000 + 1500;
        //   break;
        // case 1:
        //   waitTime = Math.random() * 2000 + 2500;
        //   break;
        // case 2:
        //   waitTime = Math.random() * 2000 + 2500;
        //   break;
        // case 3:
        //   waitTime = Math.random() * 2000 + 2000;
        //   break;
        default:
          waitTime = 0;
          break;
      }
      setTimeout(() => {
        if (step === stepNumber - 1) {
          onUploadingAnimationFinished();
        } else {
          setStep(step + 1);
        }
      }, waitTime);
    }
  }, [step, onUploadingAnimationFinished]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: "0px",
        left: "0px",
        margin: "0px",
        // backgroundColor: "rgba(248, 248, 248, 0.85)",
        backgroundColor: "transparent",
        zIndex: "1000000",
      }}
    >
      {/* <div>
        <Lottie animationData={loadingAnimation} style={{ height: "200px" }} />
        <div>
          <p
            style={{
              width: "350px",
              textAlign: "center",
              fontWeight: "600",
              lineHeight: "25px",
            }}
          >
            {loadingText[step]}
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default UploadingAnimation;
