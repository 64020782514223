import { useState, useRef } from "react";
import $ from "jquery";
import useChineseHighSchoolWords from "./useChineseHighSchoolWords";

const serverUrl = "https://wordly-server-hlxo6jmzoq-uc.a.run.app/";
const randomWords = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "lac",
  "en",
  "rig",
  "cops",
  "morn",
  "♪",
  "goo",
];

const useSubtitle = (transcriptionArrayRef) => {
  const { chineseHighSchoolWordsRef } = useChineseHighSchoolWords();

  const [unknownWordArray, setUnknownWordArray] = useState([]);
  const unknownWordArrayRef = useRef([]);

  const numberOfFinalResultRef = useRef(0);

  // useEffect(() => {
  //   console.log(unknownWordArray);
  // }, [unknownWordArray]);

  //We store all previous interim_transcript here
  const previousInterimCaptionsRef = useRef([]);
  const setUseSubtitleTranscript = (
    interim_transcript,
    final_transcript,
    hasFinalResult
  ) => {
    //Sharedwords is word array that is shared by current interim_transcript and last interim_transcript(if it exists)
    var sharedWords;
    //finalWords is the final caption being split to a word array
    var finalWords;

    switch (hasFinalResult) {
      case true:
        finalWords = final_transcript.toLowerCase().trim().split(" ");
        previousInterimCaptionsRef.current = [];
        break;

      case false:
        var previousCaption =
          previousInterimCaptionsRef.current.length > 0
            ? previousInterimCaptionsRef.current[
                previousInterimCaptionsRef.current.length - 1
              ].trim()
            : "";
        var currentCaption = interim_transcript.trim();
        //Get shared Words
        sharedWords = getSharedWords(currentCaption, previousCaption);
        previousInterimCaptionsRef.current.push(currentCaption);
        break;

      default:
        break;
    }
    examSharedWords(sharedWords, finalWords, hasFinalResult);
  };

  const lastSharedWordsRef = useRef([]);
  const examSharedWords = (sharedWords, finalWords, hasFinalResult) => {
    //New words are what we need to examize
    var newWords;
    //Get last shared words because they have been examized
    const lastSharedWords = lastSharedWordsRef.current;
    //if this is final, then we need a different way to examize new words
    switch (hasFinalResult) {
      case true:
        lastSharedWordsRef.current = [];
        newWords =
          finalWords.length > lastSharedWords.length
            ? finalWords.slice(lastSharedWords.length, finalWords.length)
            : [];
        break;

      case false:
        newWords =
          lastSharedWords.length !== 0
            ? getNewWords(sharedWords, lastSharedWords)
            : sharedWords;
        break;

      default:
        break;
    }

    //Only when newWords is not empty, we check the words
    if (newWords.length !== 0) {
      var finalResultNumberForWords = numberOfFinalResultRef.current;
      examNewWords(newWords, finalResultNumberForWords);
    }

    numberOfFinalResultRef.current = hasFinalResult
      ? numberOfFinalResultRef.current + 1
      : numberOfFinalResultRef.current;

    lastSharedWordsRef.current = sharedWords ? sharedWords : [];
  };

  function examNewWords(newWords, finalResultNumberForWords) {
    newWords.forEach((word) => {
      // console.log(word);
      if (
        !chineseHighSchoolWordsRef.current.includes(word) &&
        !randomWords.includes(word)
      ) {
        const currentURL = window.location.href;
        var settings = {
          async: true,
          crossDomain: true,
          url: serverUrl + "word",
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": currentURL,
          },
          data: {
            word: word,
          },
        };
        $.ajax(settings).done(function (response) {
          var ifKnown = response;
          if (!ifKnown) {
            //get translation and display it on the screen
            handleNewUnknownWord(word, finalResultNumberForWords);
          }
        });
      }
    });
  }

  const handleNewUnknownWord = (keyword, finalResultNumberForWords) => {
    const settings = {
      async: true,
      crossDomain: true,
      url: "https://google-translate1.p.rapidapi.com/language/translate/v2",
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        // "accept-encoding": "application/gzip",
        "x-rapidapi-key": "89561a7c12msh44b07092cf18492p12e4d1jsn762b4ac816c6",
        "x-rapidapi-host": "google-translate1.p.rapidapi.com",
      },
      data: {
        q: keyword,
        source: "en",
        target: "zh-CN",
      },
    };
    //go to server
    $.ajax(settings).done(function (response) {
      var translation = response.data.translations[0].translatedText;
      //displayTranslation
      displayNewUnknownWord(keyword, translation, finalResultNumberForWords);
      //save it to chrome storage
    });
  };

  const displayNewUnknownWord = (
    keyword,
    translation,
    finalResultNumberForWords
  ) => {
    // console.log(keyword);
    // console.log(translation);
    const newWordObject = {
      keyword: keyword,
      translation: translation,
      // timeStamp: Date.now(),
      // onHover: false,
    };
    if (!ifTranscriptionHasKeyword(keyword, finalResultNumberForWords)) return;
    var currentUnknownArray = unknownWordArrayRef.current;
    while (currentUnknownArray.length - 1 < finalResultNumberForWords) {
      currentUnknownArray.push([]);
    }
    currentUnknownArray[finalResultNumberForWords].unshift(newWordObject);
    currentUnknownArray[finalResultNumberForWords] = getUniqueArray(
      currentUnknownArray[finalResultNumberForWords]
    );
    //set status
    console.log(currentUnknownArray);
    unknownWordArrayRef.current = currentUnknownArray;
    setUnknownWordArray([...currentUnknownArray]);
    //remove newUnknownWordList in the future
    // removeNewWordObjectInFuture(newWordObject);
  };

  const ifTranscriptionHasKeyword = (keyword, finalResultNumberForWords) => {
    var transcriptionString =
      transcriptionArrayRef.current[finalResultNumberForWords].transcription;
    var transcriptionArray = transcriptionString
      .toLowerCase()
      .trim()
      .split(" ");
    return transcriptionArray.includes(keyword);
  };

  //I don't know what this function is for ... Sorry
  // function removeNewWordObjectInFuture(newWordObject) {
  //   //this line is important, if not added, newWordObject will be changed
  //   //when timer is up so the original word should be deleted wouldn't be deleted
  //   var wordObjectToDelete = newWordObject;
  //   const displayDuration = 5000;
  //   //filter out newWordObject
  //   setTimeout(function () {
  //     var currentUnknownArray = unknownWordArrayRef.current;
  //     currentUnknownArray = currentUnknownArray.filter((wordObject) => {
  //       if (
  //         wordObject.keyword === wordObjectToDelete.keyword &&
  //         wordObject.timeStamp === wordObjectToDelete.timeStamp
  //       ) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     });
  //     unknownWordArrayRef.current = currentUnknownArray;
  //     setUnknownWordArray([...currentUnknownArray]);
  //   }, displayDuration);
  // }

  function getUniqueArray(arr) {
    var keywords = [];
    var ret_arr = [];
    for (var i = 0; i < arr.length; i++) {
      var keyword = arr[i].keyword;
      if (!keywords.includes(keyword)) {
        ret_arr.push(arr[i]);
        keywords.push(arr[i].keyword);
      }
    }
    return ret_arr;
  }

  const getNewWords = (currentWords, lastWords) => {
    if (lastWords.length === 0) return [];
    if (currentWords.length === 0) return [];
    var length =
      currentWords.length > lastWords.length
        ? lastWords.length
        : currentWords.length;
    var i;
    for (i = 0; i < length; i++) {
      if (currentWords[i] !== lastWords[i]) {
        return currentWords.slice(i, currentWords.length);
      }
      if (i === length - 1) {
        return currentWords.slice(i + 1, currentWords.length);
      }
    }
  };

  const getSharedWords = (str1, str2) => {
    var str1Words = str1.toLowerCase().trim().split(" ");
    var str2Words = str2.toLowerCase().trim().split(" ");
    var length =
      str1Words.length > str2Words.length ? str2Words.length : str1Words.length;
    var i;
    for (i = 0; i < length; i++) {
      if (str1Words[i] !== str2Words[i]) {
        return str1Words.slice(0, i);
      }
      if (i === length - 1) {
        return str1Words.slice(0, i + 1);
      }
    }
  };

  return { unknownWordArray, setUseSubtitleTranscript, unknownWordArrayRef };
};

export default useSubtitle;
