import React from "react";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import disableScrollPlugin from "./disableScrollPlugin";

const PdfCard = ({ date, title, url, onPdfCardClick, pdfCardRef, id }) => {
  const disableScrollPluginInstance = disableScrollPlugin();

  const styles = {
    container: {
      position: "relative",
      width: "200px",
      marginRight: "40px",
      marginBottom: "40px",
    },
    pdf: { width: "100%", height: "200px" },
    button: {
      position: "absolute",
      top: "0%",
      left: "0%",
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",

      padding: "12px 24px",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.pdf}>
        <Viewer
          fileUrl={url}
          defaultScale={SpecialZoomLevel.PageFit}
          initialPage={0}
          plugins={[disableScrollPluginInstance]}
        />
      </div>
      <button
        style={styles.button}
        onClick={() => onPdfCardClick(url, title, id)}
        ref={pdfCardRef}
      ></button>
    </div>
  );
};

export default PdfCard;
