import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";

import googleIcon from "../../assets/googleIcon.svg";
import closeIcon from "../../assets/closeIcon.svg";

import PopupContainer from "../common/popupContainer";
import useAuth from "../../auth/useAuth";
import useAnalytics from "../../analytics/useAnalytics";

const AuthPopup = ({ onClosePopup }) => {
  const [error, setError] = useState("");
  const [mode, setMode] = useState("SIGN_UP");
  const { t } = useTranslation();
  const { signUpWithPassword, signInWithPassword, signInWithGoogle } =
    useAuth();
  const { logEvent } = useAnalytics();

  const handlePrimaryFormButtonClick = async () => {
    var password = $("#inputPassword").val();
    var email = $("#inputEmail").val();

    if (mode === "SIGN_UP") {
      logEvent("sign-up", t("sign-up-label"));
      try {
        await signUpWithPassword(email, password);
      } catch (error) {
        setError(error.message);
      }
    } else {
      logEvent("sign-in", t("sign-in-label"));
      try {
        await signInWithPassword(email, password);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleContinueWithGoogle = async () => {
    logEvent("continue-with-google", t("continue-with-google-label"));
    try {
      await signInWithGoogle();
    } catch (error) {
      setError(error.message);
    }
  };

  const styles = {
    container: {
      backgroundColor: "white",
      width: "390px",
      borderRadius: "10px",
    },
    fullscreen: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0px",
      left: "0px",
      // backgroundColor: "coral",
      backgroundSize: "cover",
    },
    leftPanel: {
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      backgroundSize: "cover",
    },
    rightPanel: {
      backgroundColor: "white",
    },
    logoWithTitle: {
      width: 140,
    },
    title: {
      color: "white",
      fontSize: "2.3rem",
      fontWeight: "500",
    },
    titleRow: {
      marginTop: "0rem",
      marginLeft: "0rem",
    },
    displayImage: {
      marginTop: "30px",
      display: "inline-block",
      maxWidth: "100%",
    },
    displayRow: {
      marginTop: "3rem",
      marginLeft: "4rem",
    },
    controllerRow: {
      marginTop: "0.5rem",
    },
    circleButton: {
      border: 0,
      outline: 0,
    },
    circleButtonImg: {
      height: 8,
    },
    googleIcon: { marginRight: "12px", height: "15px" },
    closeIcon: { height: "13px" },
    closeButton: {
      marginRight: "20px",
      marginTop: "20px",
    },
  };

  const handleSecondaryButtonClick = () => {
    if (mode === "SIGN_UP") {
      setMode("SIGN_IN");
      logEvent("sign-in-instead", t("sign-in-instead-label"));
    } else {
      setMode("SIGN_UP");
      logEvent("sign-up-instead", t("sign-up-instead-label"));
    }
  };

  return (
    <PopupContainer backgroundColor="rgba(40, 40, 40, 0.3)">
      <div style={styles.container}>
        <div id="close-row" className="d-flex justify-content-end">
          <button
            className="wordleap-img-button"
            style={styles.closeButton}
            onClick={onClosePopup}
          >
            <img
              src={closeIcon}
              className="wordleap-img-button-img"
              style={styles.closeIcon}
            />
          </button>
        </div>
        <div id="google-row" className="mt-4 mx-4">
          <button
            className="btn w-100 btn-outline-dark mb-3"
            onClick={handleContinueWithGoogle}
          >
            <img src={googleIcon} style={styles.googleIcon} />
            {t("continue-with-google")}
          </button>
        </div>
        <div id="or-row" className="mt-0 mx-4">
          <div className="w-100 d-flex justify-content-center">or</div>
        </div>
        <div className="mt-3 mx-4">
          <div>
            <label htmlFor="inputEmail" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              id="inputEmail"
              className="form-control mb-3 w-100"
              placeholder="Email address"
              required=""
              autoFocus=""
            />
            <label htmlFor="inputPassword" className="sr-only">
              Password
            </label>
            <input
              type="password"
              id="inputPassword"
              className="form-control"
              placeholder="Password"
              required=""
            />

            {error !== "" && (
              <div className="alert alert-danger mt-3">{error}</div>
            )}
          </div>
        </div>

        <div className="mt-3 mx-4">
          <div>
            <button
              className="btn w-100 btn-primary mb-3"
              id="primary-form-button"
              onClick={handlePrimaryFormButtonClick}
            >
              {mode === "SIGN_UP" ? t("sign-up") : t("sign-in")}
            </button>

            <button
              className="btn w-100 btn-link mb-4"
              id="secondary-form-button"
              style={styles.googleButton}
              onClick={handleSecondaryButtonClick}
            >
              {mode === "SIGN_UP" ? t("sign-in-instead") : t("sign-up-instead")}
            </button>
            {mode === "SIGN_UP" ? (
              <p className="small text-center text-secondary mb-5">
                By signing up, you agree to our{" "}
                <a href={"https://wordly-privacy-policy.herokuapp.com/"}>
                  privacy policy
                </a>
              </p>
            ) : (
              <p className="small text-center text-secondary mb-5">
                By signing in, you agree to our{" "}
                <a href={"https://wordly-privacy-policy.herokuapp.com/"}>
                  privacy policy
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </PopupContainer>
  );
};

export default AuthPopup;
