import React from "react";

const FullScreenPageWrapper = ({ children }) => {
  const styles = {
    fullscreen: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0px",
      left: "0px",
      margin: "0px",
      //   backgroundColor: "coral",
      backgroundSize: "cover",
    },
  };
  return (
    <div className="row" style={styles.fullscreen}>
      {children}
    </div>
  );
};

export default FullScreenPageWrapper;
