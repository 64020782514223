// import firebase from "../services/firebase";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

const useAnalytics = () => {
  const logEvent = (action, label) => {
    if (!label) {
      ReactGA.event({
        category: "user",
        action: action,
      });
      // ReactPixel.trackCustom(action);
      // console.log(action + " / ");
    } else {
      ReactGA.event({
        category: "user",
        action: action,
        label: label,
      });
      ReactPixel.trackCustom(action, { label: label });
      // console.log(action + " / " + label);
    }
  };

  // const logEvent = (event, data) => {
  //   const testing = false;
  //   switch (testing) {
  //     case true:
  //       console.log("i got here");
  //       if (!data) {
  //         firebase.analytics().logEvent(event, { debug_mode: 1 });
  //       } else {
  //         var newData = { ...data };
  //         newData["debug_mode"] = 1;
  //         firebase.analytics().logEvent(event, newData);
  //       }
  //       break;
  //     case false:
  //       if (!data) {
  //         firebase.analytics().logEvent(event);
  //         console.log(event);
  //       } else {
  //         firebase.analytics().logEvent(event, data);
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // };

  // const logScreenView = (screenName) => {
  //   const testing = true;
  //   switch (testing) {
  //     case true:
  //       console.log("i got here");
  //       firebase
  //         .analytics()
  //         .logEvent("page_view_" + screenName, { debug_mode: 1 });
  //       break;
  //     case false:
  //       firebase.analytics().logEvent("page_view_" + screenName);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return { logEvent };
};

export default useAnalytics;
