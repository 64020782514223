import React, { useState, useEffect } from "react";

const PopupContainer = ({
  children,
  backgroundColor = "rgba(248, 248, 248, 0.85)",
}) => {
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    setY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", (e) => setY(e.currentTarget.scrollY));
    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) =>
        setY(e.currentTarget.scrollY)
      );
    };
  }, [y]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: y + "px",
        left: "0px",
        margin: "0px",
        backgroundColor: backgroundColor,
        zIndex: "1000000",
      }}
    >
      {children}
    </div>
  );
};

export default PopupContainer;
