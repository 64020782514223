import React from "react";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

const DropDownButton = ({
  title,
  options,
  onSelect,
  size,
  variant = "primary",
}) => {
  //   const title = "filter";
  //   const options = ["option1", "option2", "option3"];
  // size is either 'sm' or 'lg'
  // varient can be ['primary', 'secondary', 'success', 'info', 'warning', 'danger']

  const styles = {
    dropDownButton: {
      border: "1px",
      backgroundColor: "white",
      fontWeight: "500x",
    },
  };

  return (
    <DropdownButton
      alignleft="true"
      title={title}
      id="dropdown-menu-align-left"
      variant={variant}
      onSelect={onSelect}
      className="mr-2"
      style={styles.dropDownButton}
      size={size}
    >
      {options.map((option) => (
        <Dropdown.Item
          key={option}
          eventKey={option}
          style={{
            color: title === option ? "#33A747" : "#373737",
            fontWeight: "400",
          }}
        >
          {option}
        </Dropdown.Item>
      ))}
      {/* <Dropdown.Divider /> */}
    </DropdownButton>
  );
};

export default DropDownButton;
