import React from "react";

const RadioButtonGroup = ({ options, answer, setAnswer, ifWrap = false }) => {
  const handleButtonClick = (option) => {
    setAnswer(option);
  };

  return (
    <div className="row mx-0">
      {options.map((option, i) => {
        return (
          <div
            className={ifWrap ? "col-12 mb-2 px-0 mx-0" : "mr-2 mb-2"}
            key={option}
          >
            <button
              className={
                answer === option
                  ? "btn  px-4 radio-button button-selected"
                  : "btn  px-4 radio-button"
              }
              onClick={() => handleButtonClick(option)}
            >
              {option}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default RadioButtonGroup;
