import React from "react";

const RoutesPageWrapper = ({ location, children }) => {
  const styles = {
    page: { height: "100%", display: "bloack", boxSizing: "border-box" },
    paddingBox: {
      background: "#f6f6f6",
      display: "table",
      width: "100%",
      height: "100%",
      position: "relative",
      boxSizing: "border-box",
    },
  };

  if (
    location.pathname !== "/" &&
    location.pathname !== "/en" &&
    location.pathname !== "/login" &&
    location.pathname !== "/personalize"
  )
    return (
      <div style={styles.page}>
        <div style={styles.paddingBox}>{children} </div>
      </div>
    );
  else return <div>{children}</div>;
};

export default RoutesPageWrapper;
