import React, { useState, useRef } from "react";
import pdfIcon from "../../../assets/pdfIcon.svg";

const UploadPdfCard = ({ onPdfUploadButtonClick, uploadPdfCardRef }) => {
  const [buttonHovered, setButtonHovered] = useState(false);

  const styles = {
    container: {
      position: "relative",
      width: "200px",
      marginRight: "40px",
      marginBottom: "40px",
    },
    pdf: { width: "100%", height: "200px" },
    button: {
      position: "absolute",
      top: "0%",
      left: "0%",
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      padding: "12px 24px",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
    },
    fakePdf: buttonHovered
      ? {
          height: "183.991px",
          width: "143.173px",
          backgroundColor: "white",
          boxShadow: "rgba(51, 167, 71, 0.6) 2px 2px 8px 0px",
        }
      : {
          height: "183.991px",
          width: "143.173px",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 8px 0px",
        },
  };

  return (
    <React.Fragment>
      <div style={styles.container}>
        <div
          style={styles.pdf}
          className="d-flex align-items-center justify-content-center"
        >
          <div
            id="fake-pdf"
            style={styles.fakePdf}
            ref={uploadPdfCardRef}
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ marginBottom: "12px" }}
              >
                <img src={pdfIcon} style={{ height: "35px" }} />
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div
                  style={
                    buttonHovered
                      ? {
                          fontSize: "16px",
                          color: "rgb(51, 167, 71)",
                          fontWeight: "500",
                        }
                      : {
                          fontSize: "16px",
                          color: "rgb(86, 86, 86)",
                          fontWeight: "500",
                        }
                  }
                >
                  Upload New
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          style={styles.button}
          onClick={onPdfUploadButtonClick}
          onMouseEnter={() => setButtonHovered(true)}
          onMouseLeave={() => setButtonHovered(false)}
        ></button>
      </div>
    </React.Fragment>
  );
};

export default UploadPdfCard;
