import React, { useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import $ from "jquery";

import { useTranslation } from "react-i18next";

import AuthContext from "../context/authContext";

import LogInPage from "../pages/logInPageNew";

import RoutesPageWrapper from "./routesPageWrapper";
import MeetingCaptionContent from "../components/home/meetingCaption/meetingCaptionContent";
import NewCaptionContent from "../components/home/newCaption/newCaptionContent";
import WordListContent from "../components/home/wordList/wordListContent";
import SideNavBar from "../components/home/nav/sideNavBar";
import RoutesContentContainer from "./routesContentContainer";
import CaptionDetailContent from "../components/home/captionDetail/captionDetailContent";
import PersonalizePage from "../components/personalize/personalizePage";
import PdfContent from "../components/home/pdf/pdfContent";
import LandingPageChinese from "../components/intro/landingPage";
import UseCloudServerColdStart from "../components/common/useCloudServerColdStart";

const PrivateRoute = ({ location }) => {
  const { user, loadingUser, personalized } = useContext(AuthContext);
  const { i18n } = useTranslation();
  UseCloudServerColdStart();

  useEffect(
    function () {
      const path = location.pathname;
      ReactGA.set({ page: path });
      ReactGA.pageview(path); // Record a pageview for the given page
      if (path === "/") {
        i18n.changeLanguage("zn");
      }
      if (path === "/en") {
        i18n.changeLanguage("en");
      }
    },
    [location]
  );

  // const styles = {
  //   page: { height: "100%", display: "bloack", boxSizing: "border-box" },
  //   paddingBox: {
  //     background: "#f6f6f6",
  //     display: "table",
  //     width: "100%",
  //     height: "100%",
  //     position: "relative",
  //     boxSizing: "border-box",
  //   },
  //   leftMenuContent: {
  //     height: "100vh",
  //     boxSizing: "border-box",
  //     width: "300px",
  //     position: "absolute",
  //     background: "white",
  //   },
  // };

  return (
    <React.Fragment>
      <RoutesPageWrapper location={location}>
        {location.pathname !== "/" &&
          location.pathname !== "/en" &&
          location.pathname !== "/personalize" &&
          (personalized === true || personalized === "skipped") && (
            <SideNavBar />
          )}

        <RoutesContentContainer location={location}>
          <Route path="/captions" exact component={MeetingCaptionContent} />

          <Route path="/newcaption" exact component={NewCaptionContent} />

          <Route path="/wordlist" exact component={WordListContent} />

          <Route path="/pdf" exact component={PdfContent} />

          <Route path="/caption/:id" component={CaptionDetailContent} />

          {/* <Route path="/login" exact component={LogInPage} /> */}

          <Route path="/personalize" exact component={PersonalizePage} />

          <Route path="/en" exact component={LandingPageChinese} />

          <Route path="/" exact component={LandingPageChinese} />

          {!loadingUser &&
            personalized !== null &&
            user &&
            (personalized === true || personalized === "skipped") &&
            location.pathname !== "/wordlist" &&
            location.pathname !== "/pdf" && <Redirect to="/pdf" />}

          {!loadingUser &&
            personalized !== null &&
            user &&
            personalized === false &&
            location.pathname !== "/personalize" && (
              <Redirect to="/personalize" />
            )}

          {!loadingUser &&
            !user &&
            // location.pathname !== "/login" &&
            location.pathname !== "/" && <Redirect to="/" />}
        </RoutesContentContainer>
      </RoutesPageWrapper>
    </React.Fragment>
  );
};

export default PrivateRoute;
