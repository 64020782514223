import React, { useContext, useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

import CaptionHead from "../../../transcription/captionHead";
import TranscriptionArea from "../../../transcription/transcriptionArea";
import AuthContext from "../../../context/authContext";
import useFirestore from "../../../firestore/useFirestore";

const CaptionDetailContent = () => {
  const { deleteCaption, updateCaptionTitle } = useFirestore();
  let history = useHistory();
  const { captionDocuments } = useContext(AuthContext);
  const match = useRouteMatch("/caption/:id");
  const captionID = match.params.id;
  const [captionDoc, setCaptionDoc] = useState(null);
  const [title, setTitle] = useState("");

  useEffect(() => {
    console.log(captionDocuments);
    captionDocuments.forEach((captionDocument) => {
      if (captionDocument.id === captionID) {
        console.log(captionDocument);
        setCaptionDoc(captionDocument);
        setTitle(captionDocument.title);
      }
    });
  }, [captionDocuments, captionID]);

  const handleGoBack = async () => {
    //set Title
    if (captionDoc) {
      await updateCaptionTitle(captionDoc.initDate, title);
    }
    //Go back
    history.push("/captions");
  };

  const handleDelete = () => {
    if (captionDoc) {
      deleteCaption(captionDoc.initDate);
    }
    history.push("/captions");
  };

  return (
    <div className="mt-5 mb-4">
      <div className="d-flex justify-content-between">
        <button className="btn btn-primary mb-4" onClick={handleGoBack}>
          Back
        </button>
        <button className="btn btn-outline-danger mb-4" onClick={handleDelete}>
          Delete
        </button>
      </div>

      {captionDoc && (
        <React.Fragment>
          <CaptionHead
            title={title}
            date={captionDoc.initDate}
            setTitle={setTitle}
          />
          <TranscriptionArea
            transcriptionArray={captionDoc.transcriptionArray}
            unknownWordArray={captionDoc.unknownWordArray}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default CaptionDetailContent;
