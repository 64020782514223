import React, { useState, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";

import useAuth from "../../../auth/useAuth";

import NavigationMenuItem from "./navigationMenuItem";

import logoWithTitleInGrey from "../../../assets/logoWithTitleInGrey.png";
import activeWordListIcon from "../../../assets/activeWordListIcon.svg";
import inactiveWordListIcon from "../../../assets/inactiveWordListIcon.svg";
import signOutIcon from "../../../assets/signOutIcon.svg";
import activeMeetingCaptionIcon from "../../../assets/activeMeetingCaptionIcon.svg";
import inactiveMeetingCaptionIcon from "../../../assets/inactiveMeetingCaptionIcon.svg";
import feedbackIcon from "../../../assets/feedbackIcon.svg";
import useAnalytics from "../../../analytics/useAnalytics";

const SideNavBar = () => {
  const { logEvent } = useAnalytics();
  const [activeMenuItem, setActiveMenuItem] = useState();
  const { signOut } = useAuth();
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    switch (location.pathname) {
      case "/captions":
        setActiveMenuItem("MEETING_CAPTION");
        break;
      case "/pdf":
        setActiveMenuItem("PDF");
        break;
      case "/newcaption":
        setActiveMenuItem("MEETING_CAPTION");
        break;
      case "/wordlist":
        setActiveMenuItem("WORD_LIST");
        break;
      default:
        setActiveMenuItem("MEETING_CAPTION");
        break;
    }
  }, [location]);

  const handleLogOut = async () => {
    console.log("log out");
    logEvent("log-out", "log-out");
    try {
      await signOut();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleFeedback = () => {
    logEvent("send-feedback", "send-feedback");
    window.open("mailto:wordleap@gmail.com");
  };

  const handleWordListClick = () => {
    logEvent("go-to-wordlist", "go-to-wordlist");
    history.push("/wordlist");
  };

  // const handleMeetingCaptionClick = () => {
  //   history.push("/captions");
  // };

  const handlePDFClick = () => {
    logEvent("go-to-pdf", "go-to-pdf");
    console.log("go to pdf");
    history.push("/pdf");
  };

  const styles = {
    homePageNavBar: {
      height: "100vh",
      boxSizing: "border-box",
      width: "300px",
      position: "absolute",
      background: "white",
      padding: "50px 0 0 50px",
    },

    logo: {
      display: "inline-block",
      height: "32px",
      position: "relative",
      marginLeft: "-5px",
    },
    navigationMenu: {
      boxSizing: "border-box",
      animationIterationCount: "1!important",
      margin: "64px 0 0",
    },
    upperNavigationMenu: {
      boxSizing: "border-box",
      animationIterationCount: "1!important",
      height: "calc(100vh - 205px)",
      minHeight: "227px",
    },
    lowerNavigationMenu: {
      boxSizing: "border-box",
      animationIterationCount: "1!important",
      width: "100%",

      height: "45px",
      lineHeight: "45px",
      display: "inline-block",
      position: "relative",
      fontSize: "17px",
      marginTop: "auto",
    },
  };

  return (
    <div style={styles.homePageNavBar} className="shadow">
      <a style={styles.logo} href="https://www.wordleap.co/">
        <img src={logoWithTitleInGrey} alt="Logo" style={{ height: 32 }} />
      </a>

      <div style={styles.navigationMenu}>
        <div style={styles.upperNavigationMenu}>
          <NavigationMenuItem
            onClick={handlePDFClick}
            activeIconSrc={activeMeetingCaptionIcon}
            inactiveIconSrc={inactiveMeetingCaptionIcon}
            isActive={activeMenuItem === "PDF"}
            buttonText={"PDF"}
          />
          <NavigationMenuItem
            onClick={handleWordListClick}
            activeIconSrc={activeWordListIcon}
            inactiveIconSrc={inactiveWordListIcon}
            isActive={activeMenuItem === "WORD_LIST"}
            buttonText={"Word List"}
          />

          <NavigationMenuItem
            onClick={handleFeedback}
            inactiveIconSrc={feedbackIcon}
            isActive={false}
            buttonText={"Feedback"}
          />
          {/* <NavigationMenuItem
            onClick={handleMeetingCaptionClick}
            activeIconSrc={activeMeetingCaptionIcon}
            inactiveIconSrc={inactiveMeetingCaptionIcon}
            isActive={
              activeMenuItem === "MEETING_CAPTION" ||
              activeMenuItem === "NEW_CAPTION"
            }
            buttonText={"Captions"}
          /> */}
        </div>
        <div style={styles.lowerNavigationMenu}>
          <NavigationMenuItem
            onClick={handleLogOut}
            inactiveIconSrc={signOutIcon}
            isActive={false}
            buttonText={"Sign Out"}
          />
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
