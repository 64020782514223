import React from "react";
import SplittedProgressBarFiller from "./splittedProgressBarFiller";

const SplittedProgressBar = ({ step, stepNumber }) => {
  const styles = {
    wrapper: {
      height: "50px",
      width: "100%",
      backgroundColor: "white",
      margin: "0px",
    },
  };
  return (
    <div className="row d-flex align-items-center" style={styles.wrapper}>
      {[...Array(stepNumber)].map((e, i) => {
        return (
          <div className="col" key={i}>
            <SplittedProgressBarFiller active={i < step ? true : false} />
          </div>
        );
      })}
    </div>
  );
};

export default SplittedProgressBar;
