import { useContext, useState, useEffect, useRef, useCallback } from "react";

import AuthContext from "../../../context/authContext";
import UseFirestoreForWordList from "../../../firestore/useFirestoreForWordList";

const useFilterAndSortWords = () => {
  const { collectedWordsLoaded, collectedWords } = useContext(AuthContext);
  const { wordDocs, wordDocsLoaded } = UseFirestoreForWordList();
  const [displayWords, setDisplayWords] = useState([]);
  const displayWordsRef = useRef([]);

  const [sortState, setSortState] = useState("Latest");
  const [filterState, setFilterState] = useState("Collected");

  const getDisplayWordsWithTime = useCallback((currentDisplayWords) => {
    var displayWordsWithTime = [];
    currentDisplayWords.forEach((word) => {
      const history = word.history;
      var mostRecentDate = null;
      for (const date in history) {
        if (mostRecentDate === null) {
          mostRecentDate = date;
        } else {
          if (isSecondDateMoreRecent(mostRecentDate, date)) {
            mostRecentDate = date;
          }
        }
      }
      displayWordsWithTime.push({ mostRecentDate: mostRecentDate, word: word });
    });
    return displayWordsWithTime;
  }, []);

  const sortDisplayWordsWithTimeByMostRecentlyCollected = useCallback(
    (displayWordsWithTime) => {
      var sortedDisplayWordsWithTime = [];
      displayWordsWithTime.forEach((wordWithTime) => {
        if (sortedDisplayWordsWithTime.length === 0) {
          sortedDisplayWordsWithTime.push(wordWithTime);
          return;
        }
        var hasPushed = false;
        for (let i = 0; i < sortedDisplayWordsWithTime.length; i++) {
          if (
            isSecondDateMoreRecent(
              sortedDisplayWordsWithTime[i].mostRecentDate,
              wordWithTime.mostRecentDate
            )
          ) {
            sortedDisplayWordsWithTime.splice(i, 0, wordWithTime);
            hasPushed = true;
            break;
          }
        }
        if (hasPushed === false) {
          sortedDisplayWordsWithTime.push(wordWithTime);
        }
      });
      return sortedDisplayWordsWithTime;
    },
    []
  );

  const getSortedWordsByMostRecentlyCollected = useCallback(() => {
    //Let's get word's most recent edit time
    var displayWordsWithTime = getDisplayWordsWithTime(displayWordsRef.current);
    //Let's sort it
    var sortedDisplayWordsWithTime =
      sortDisplayWordsWithTimeByMostRecentlyCollected(displayWordsWithTime);
    var sortedDisplayWords = [];
    sortedDisplayWordsWithTime.forEach((wordWithTime) => {
      sortedDisplayWords.push(wordWithTime.word);
    });
    return sortedDisplayWords;
  }, [
    getDisplayWordsWithTime,
    sortDisplayWordsWithTimeByMostRecentlyCollected,
  ]);

  const isSecondDateMoreRecent = (a, b) => {
    var aDate = new Date(a);
    var bDate = new Date(b);
    if (aDate - bDate < 0) {
      return true;
    } else {
      return false;
    }
  };

  const getCollectedWords = () => {
    var filteredWords = [];
    displayWordsRef.current.forEach((word) => {
      if (word.status === "collected") {
        filteredWords.push(word);
      }
    });
    return filteredWords;
  };

  const getMasteredWords = () => {
    var filteredWords = [];
    displayWordsRef.current.forEach((word) => {
      // console.log(word.status);
      if (word.status === "mastered") {
        filteredWords.push(word);
      }
    });
    // console.log(filteredWords);
    return filteredWords;
  };

  const sortAndFilterWords = useCallback(() => {
    if (!wordDocsLoaded) return;
    displayWordsRef.current = wordDocs;
    //Filter words
    switch (filterState) {
      case "Collected":
        displayWordsRef.current = getCollectedWords();
        break;
      case "Mastered":
        displayWordsRef.current = getMasteredWords();
        break;
      case "All":
        break;
      default:
        console.log("Error Filter State");
    }
    //Sort words
    if (displayWordsRef.current.length > 1) {
      switch (sortState) {
        case "A to Z":
          //collectedWords is sorted from A to Z by default
          //Nothing needs to be done
          break;
        case "Latest":
          displayWordsRef.current = getSortedWordsByMostRecentlyCollected();
          break;
        default:
          console.log("Error Sort State");
      }
    }
    //Set words
    setDisplayWords([...displayWordsRef.current]);
    // console.log([...displayWordsRef.current]);
  }, [
    wordDocs,
    wordDocsLoaded,
    filterState,
    sortState,
    getSortedWordsByMostRecentlyCollected,
  ]);

  useEffect(() => {
    sortAndFilterWords();
  }, [sortAndFilterWords]);

  return {
    collectedWordsLoaded,
    collectedWords,
    sortState,
    filterState,
    setSortState,
    setFilterState,
    displayWords,
    wordDocsLoaded,
    wordDocs,
  };
};

export default useFilterAndSortWords;
