import { useContext, useState, useEffect, useCallback } from "react";
import AuthContext from "../../../context/authContext";

const useCaptionDocuments = () => {
  const { captionDocuments } = useContext(AuthContext);
  const [displayCaptionDocuments, setDisplayCaptionDocuments] = useState([]);

  const sortCaptionDocumentsByMostRecent = useCallback(() => {
    var displayCaptionDocumentsTemp = [];
    captionDocuments.forEach((captionDocument) => {
      displayCaptionDocumentsTemp.unshift(captionDocument);
    });
    setDisplayCaptionDocuments([...displayCaptionDocumentsTemp]);
  }, [captionDocuments]);

  useEffect(() => {
    sortCaptionDocumentsByMostRecent(captionDocuments);
  }, [captionDocuments, sortCaptionDocumentsByMostRecent]);

  return { displayCaptionDocuments };
};

export default useCaptionDocuments;
