import { useContext, useEffect, useCallback } from "react";

import firebase from "../services/firebase";
import AuthContext from "../context/authContext";
import UseFirestoreNames from "../names/useFirestoreNames";

const UseFirestoreForPdfEditedTranslations = (
  pdfViewerLoaded,
  showPdfIdRef,
  ifShowPdf
) => {
  const { userCollName, pdfEditedTranslationCollName } = UseFirestoreNames();
  const { user, uidRef } = useContext(AuthContext);
  const db = firebase.firestore();

  const setEmptyDoc = useCallback(() => {
    console.log("set empty doc");
    const firestorePath = `${userCollName}/${uidRef.current}/${pdfEditedTranslationCollName}/${showPdfIdRef.current}`;
    const docRef = db.doc(firestorePath);
    const date = new Date().toISOString();
    const data = {};
    docRef
      .set(data)
      .then(() => {
        console.log("Success Create Pdf Doc " + date);
      })
      .catch((error) => {
        console.log("Error Create Pdf Doc " + date);
        throw error;
      });
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!ifShowPdf) return;
    if (!pdfViewerLoaded) return;
    db.collection(userCollName)
      .doc(user.uid)
      .collection(pdfEditedTranslationCollName)
      .doc(showPdfIdRef.current)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const pdfEditedTranslationsObject = doc.data();
          sendMessageToPdfjs(
            "pdfEditedTranslationsInit",
            pdfEditedTranslationsObject
          );
        } else {
          sendMessageToPdfjs("pdfEditedTranslationsInit", null);
          setEmptyDoc();
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [user, ifShowPdf, pdfViewerLoaded, setEmptyDoc]);

  const handleNewEditedTranslation = async (editedTranslation) => {
    const firestorePath = `${userCollName}/${uidRef.current}/${pdfEditedTranslationCollName}/${showPdfIdRef.current}`;
    const docRef = db.doc(firestorePath);
    const editedTranslationId = editedTranslation.id;
    const data = {};
    data[editedTranslationId] = editedTranslation;
    await docRef
      .update(data)
      .then(() => {
        const date = new Date().toISOString();
        console.log("Success Create Pdf Doc " + date);
      })
      .catch((error) => {
        const date = new Date().toISOString();
        console.log(error);
        console.log("Error Create Pdf Doc " + date);
        throw error;
      });
  };

  const sendMessageToPdfjs = useCallback((command, data) => {
    const url = window.location.href;
    if (!document.getElementById("wordleap-iframe")) return;
    document.getElementById("wordleap-iframe").contentWindow.postMessage(
      {
        source: "wordleap-parent",
        command: command,
        data: data,
      },
      url
    );
  }, []);

  return { handleNewEditedTranslation };
};

export default UseFirestoreForPdfEditedTranslations;
