import React from "react";

const SplittedProgressBarFiller = ({ active }) => {
  return (
    <div
      style={{
        backgroundColor: active ? "#FFBA02" : "#EEEEEE",
        width: "100%",
        height: "5px",
        borderRadius: "5px",
      }}
    />
  );
};

export default SplittedProgressBarFiller;
