import React, { useEffect } from "react";

const MultipleRadioButtonGroups = ({ options, onAnswerChange, answer }) => {
  const styles = {
    button: {
      width: "130px",
    },
    title: {
      width: "220px",
    },
    explainText: {
      width: "130px",
      textAlign: "center",
    },
  };

  useEffect(() => {
    var newAnswer = {};
    options.forEach((option) => {
      newAnswer[option] = "";
    });
    // console.log(newAnswer);
    onAnswerChange(newAnswer);
  }, [options, onAnswerChange]);

  const handleClick = (option, level) => {
    var newAnswer = { ...answer };
    newAnswer[option] = level;
    // console.log(newAnswer);
    // setAnswer({ ...newAnswer });
    onAnswerChange({ ...newAnswer });
  };

  return (
    <div>
      <div
        className="d-flex align-items-center mb-3"
        style={{ width: "800px" }}
      >
        <p className="mb-0" style={styles.title}></p>
        <p
          className="mr-2 mb-0 small font-weight-bold"
          style={styles.explainText}
        >
          New to most
        </p>
        <p
          className="mr-2 mb-0 small font-weight-bold"
          style={styles.explainText}
        >
          Know some
        </p>
        <p
          className="mr-2 mb-0 small font-weight-bold"
          style={styles.explainText}
        >
          Understand most
        </p>
      </div>
      {options.map((option) => {
        return (
          <div
            key={option}
            className="d-flex align-items-center mb-3"
            style={{ width: "800px" }}
          >
            <p className="mb-0 h6" style={styles.title}>
              {option}
            </p>
            <button
              className={
                answer[option] === "Beginner"
                  ? "btn radio-button mr-2 button-selected"
                  : "btn radio-button mr-2 "
              }
              style={styles.button}
              onClick={() => handleClick(option, "Beginner")}
            >
              Beginner
            </button>
            <button
              className={
                answer[option] === "Intermediate"
                  ? "btn radio-button mr-2 button-selected"
                  : "btn radio-button mr-2 "
              }
              style={styles.button}
              onClick={() => handleClick(option, "Intermediate")}
            >
              Intermediate
            </button>
            <button
              className={
                answer[option] === "Advanced"
                  ? "btn radio-button mr-2 button-selected"
                  : "btn radio-button mr-2 "
              }
              style={styles.button}
              onClick={() => handleClick(option, "Advanced")}
            >
              Advanced
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default MultipleRadioButtonGroups;
