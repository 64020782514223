import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import useWindowDimensions from "../common/useWindowDimensions";
import FirstVisibleSensor from "../common/firstVisibleSensor";

const TextColumn = ({
  title,
  titleFirstVisibleEventLabel,
  titleType = "h2",
  children,
  hasButton,
  buttonText,
  onClick,
  onButtonVisibleChange,
  signUpForm,
}) => {
  const { isMobile } = useWindowDimensions();

  return (
    <div
      className={
        isMobile
          ? "col-lg-6 col-md-6 col-sm-12 mb-3 d-flex align-items-center px-3"
          : "col-lg-6 col-md-6 col-sm-12 mb-5 d-flex align-items-center px-5"
      }
    >
      <div>
        <FirstVisibleSensor label={titleFirstVisibleEventLabel}>
          <h2
            className={
              titleType === "h1"
                ? "font-weight-bold mb-4 h1"
                : "font-weight-bold mb-4"
            }
            style={{ lineHeight: 1.25 }}
          >
            {title}
          </h2>
        </FirstVisibleSensor>

        <p
          className="mb-4"
          style={{ lineHeight: 1.5, fontSize: "1.2rem" }}
          id="carousel-paragragh"
        >
          {children}
        </p>
        {hasButton && (
          <VisibilitySensor onChange={onButtonVisibleChange}>
            <button
              className="btn btn-primary mt-4"
              style={{ height: 40, width: 250 }}
              onClick={onClick}
            >
              {buttonText}
            </button>
          </VisibilitySensor>
        )}
        {signUpForm && signUpForm}
      </div>
    </div>
  );
};

export default TextColumn;
