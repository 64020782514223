import React from "react";

import AuthContext from "./authContext";

import useAuthInit from "../auth/useAuthInit";

const AuthProvider = ({ children }) => {
  const {
    //user related
    user,
    userRef,
    uidRef,
    loadingUser,
    //personalization
    personalized,
    //collectedWords and masteredWords
    collectedWords,
    collectedWordsRef,
    masteredWords,
    masteredWordsRef,
  } = useAuthInit();

  return (
    <AuthContext.Provider
      value={{
        //user related
        user,
        userRef,
        uidRef,
        loadingUser,
        //personalization
        personalized,
        //collectedWords and masteredWords
        collectedWords,
        collectedWordsRef,
        masteredWords,
        masteredWordsRef,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
