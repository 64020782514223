import React, { useState, useEffect, useMemo, useCallback } from "react";

import useFirestore from "../../firestore/useFirestore";
import FullScreenPageWrapper from "../common/fullScreenPageWrapper";
import SplittedProgressBar from "./splittedProgressBar";
import Selections from "./selections";
import UploadingAnimation from "./uploadingAnimation";
import NoSupportScreen from "./noSupportScreen";
import useAnalytics from "../../analytics/useAnalytics";
import PersonalizationTopBar from "./personalizationTopBar";
import WelcomePopup from "./welcomePopup";

const majorList = [
  "Agriculture",
  "Business and Management",
  "Communication and Journalism",
  "Computer Science",
  "Education",
  "Engineering",
  "Fine and Applied Arts",
  "Health Professions",
  "Humanities",
  "Intensive English",
  "Legal studies and Law Enforcement",
  "Math",
  "Physical and Life Sciences",
  "Social Sciences",
  "Other",
];
const occupationList = [
  "Accountant and Auditor",
  "Architect",
  "Business Analyst",
  "Consultant",
  "Data Scientist",
  "Financial Analyst",
  "IT Project and Product Manager",
  "Management Analysts",
  "Mechanical Engineer",
  "Product Designer and Researcher",
  "Software Engineer",
  "Other",
];

const PersonalizePage = () => {
  const { logEvent } = useAnalytics();
  const {
    skipPersonalization,
    uploadPersonalizationInfo,
    uploadNativeLanguage,
  } = useFirestore();
  const [ifStudying, setIfStudying] = useState(true);
  const [answer, setAnswer] = useState("");
  const [answers, setAnswers] = useState([]);
  const [ifShowWelcomePopup, setIfShowWelcomePopup] = useState(true);

  const questionList = useMemo(() => {
    return [
      {
        question: "What is your native language?",
        storeFieldName: "nativeLanguage",
        subtitle: "Make a selection below",
        selectionType: "RadioButtonGroup",
        options: [
          "Chinese",
          "Korean",
          "Japanese",
          "Arabic",
          "French",
          "Spanish",
          "Italian",
          "Other",
        ],
      },
      {
        question: "Are you studying or working?",
        storeFieldName: "status",
        subtitle: "Make a selection below",
        selectionType: "RadioButtonGroupWrap",
        options: ["Studying", "Working", "Other"],
      },
      {
        question: ifStudying ? "I am studying in" : "My highest degree is",
        storeFieldName: "degree",
        subtitle: "Make a selection below",
        selectionType: "RadioButtonGroupWrap",
        options: ["High School", "Undergrad", "Master", "PhD", "Other"],
      },
      {
        question: ifStudying ? "My major is" : "My occupation is or will be",
        storeFieldName: ifStudying ? "major" : "job",
        subtitle: "Make a selection below",
        selectionType: "DropDownMenu",
        options: ifStudying ? majorList : occupationList,
      },
      {
        question: ifStudying
          ? "I have studied in America for"
          : "I have lived in America for",
        storeFieldName: "duration",
        subtitle: "Make a selection below",
        selectionType: "RadioButtonGroupWrap",
        options: [
          ifStudying
            ? "I am about to study in America"
            : "I am about to live in America",
          "Less than 6 month",
          "6 month - 1 year",
          "1-3 year",
          "More than 3 years",
          ifStudying
            ? "I am studying or about to study in another English-speaking country"
            : "I am living or about to live in another English-speaking country",
        ],
      },
      {
        question: "How familiar are you with vocabulary in these fields?",
        storeFieldName: "fields",
        subtitle: "Make selections below",
        selectionType: "MultipleRadioButtonGroups",
        options: [
          "General Academic",
          "Computer Science",
          "Business",
          "Medical",
          "Fitness",
        ],
      },
    ];
  }, [ifStudying]);

  const fixedTopHeight = "80px";
  const fixedBottonHeight = "40px";
  const [step, setStep] = useState(1);
  const stepNumber = 6;
  const [allowNextQuestion, setAllowNextQuestion] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [ifSpeakChinese, setIfSpeakChinese] = useState();

  //figure out if question is answered so user can move on to the next queston
  useEffect(() => {
    switch (step) {
      case 6:
        var options = questionList[5].options;
        // console.log(options);
        var ifSelectedAll = true;
        options.forEach((option) => {
          if (answer[option] === "") ifSelectedAll = false;
        });
        if (ifSelectedAll) setAllowNextQuestion(true);
        else setAllowNextQuestion(false);
        break;
      default:
        if (answer === "") setAllowNextQuestion(false);
        else setAllowNextQuestion(true);
        break;
    }
  }, [step, answer, questionList]);

  const handleSkipPersonalization = () => {
    logEvent("skip-personalization", "skip-personalization-at-" + step);
    skipPersonalization();
  };

  const handlePopupSkipPersonalization = () => {
    logEvent("skip-personalization", "skip-personalization-at-popup");
    skipPersonalization();
  };

  const handleContinue = () => {
    if (step === 1) {
      if (answer !== "Chinese") {
        let newAnswerField = {};
        newAnswerField[questionList[step - 1].storeFieldName] = answer;
        setIfSpeakChinese(false);
        uploadNativeLanguage(newAnswerField);
        return;
      }
    }
    if (step === 2) {
      if (answer === "Studying") setIfStudying(true);
      else setIfStudying(false);
    }
    if (step <= 5) {
      let newAnswerField = {};
      newAnswerField[questionList[step - 1].storeFieldName] = answer;
      // console.log([...answers, newAnswerField]);
      setAnswers([...answers, newAnswerField]);
      setAnswer("");
      setStep(step + 1);
    }
    if (step === 6) {
      let newAnswerField = {};
      newAnswerField[questionList[step - 1].storeFieldName] = answer;
      var newAnswers = [...answers, newAnswerField];
      setAnswers(newAnswers);
      setUploading(true);
    }
    logEvent("next-question", "next-question-at-" + step);
  };

  const handleUploadingAnimationFinished = useCallback(() => {
    uploadPersonalizationInfo(answers);
  }, [answers]);

  const handlePreviousQuestion = () => {
    if (step >= 2) {
      logEvent("previous-question", "previous-question-at-" + step);
      setStep(step - 1);
      setAnswer("");
      var newAnswers = [...answers];
      newAnswers.pop();
      // console.log(newAnswers);
      setAnswers([...newAnswers]);
    }
  };

  const styles = {
    progressBarContainer: {
      height: fixedTopHeight,
      width: "100%",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    mainContent: {
      WebkitTextSizeAdjust: "100%",
      WebkitFontSmoothing: "antialiased",
      color: "#0e101a",
      boxSizing: "border-box",
      display: "block",
      position: "relative",
      width: "100%",
      WebkitBoxFlex: "1",
      flex: "1",
      marginTop: "56px",
      backgroundColor: "red",
    },
    questionColumn: {
      // backgroundColor: "coral",
      paddingTop: fixedTopHeight,
      paddingBotton: fixedBottonHeight,
    },
    questionCard: { width: "540px" },
  };

  const handleMoreNativeLanguage = () => {
    // console.log("love");
    setStep(1);
    setIfSpeakChinese();
  };

  const handleStartPersonalization = () => {
    logEvent("start-personalization", "start-personalization-at-popup");
    setIfShowWelcomePopup(false);
  };

  return (
    <FullScreenPageWrapper>
      {ifShowWelcomePopup && (
        <WelcomePopup
          onStartPersonalization={handleStartPersonalization}
          onSkipPersonalization={handlePopupSkipPersonalization}
        />
      )}
      {uploading && (
        <UploadingAnimation
          onUploadingAnimationFinished={handleUploadingAnimationFinished}
        />
      )}
      {ifSpeakChinese === false && (
        <NoSupportScreen onMoreNativeLanguage={handleMoreNativeLanguage} />
      )}
      <div className="fixed-top" style={styles.progressBarContainer}>
        <SplittedProgressBar step={step} stepNumber={stepNumber} />
        <PersonalizationTopBar
          step={step}
          onPreviousQuestion={handlePreviousQuestion}
        />
      </div>
      <div
        className="col d-flex justify-content-center mt-5"
        style={styles.questionColumn}
      >
        <div style={styles.questionCard}>
          <h1>{questionList[step - 1].question}</h1>
          <p className="mt-3 mb-4">{questionList[step - 1].subtitle}</p>
          <div>
            <Selections
              selectionType={questionList[step - 1].selectionType}
              options={questionList[step - 1].options}
              setAnswer={setAnswer}
              answer={answer}
            />
          </div>
          <button
            className="btn btn-medium btn-primary mt-4 mb-5"
            onClick={handleContinue}
            disabled={!allowNextQuestion}
            style={{ width: "220px" }}
          >
            {step === 6 ? "Complete" : "Next Question"}
          </button>
        </div>
      </div>

      <div
        className="fixed-bottom d-flex justify-content-center "
        style={{
          marginBotton: fixedBottonHeight,
        }}
      >
        {!ifShowWelcomePopup && (
          <button
            className="btn btn-md btn-light"
            style={{
              color: "#7A7A7A",
              fontSize: "16px",
              marginBottom: "0.8rem",
            }}
            onClick={handleSkipPersonalization}
          >
            Skip Personalization
          </button>
        )}
      </div>
    </FullScreenPageWrapper>
  );
};

export default PersonalizePage;
