import React from "react";
import Lottie from "lottie-react";
// import pdfUploadingLottie from "../../../assets/pdfUploadingLottie.json";
import pdfUploadingLottie from "../../../assets/9150-loading-animation-circle.json";

import pdfIcon from "../../../assets/pdfIcon.svg";
import pdfUploadCompleteIcon from "../../../assets/pdfUploadCompleteIcon.svg";
import pdfUploadCloseIcon from "../../../assets/pdfUploadingCloseIcon.svg";

const PdfUploadingStatusBar = ({
  ifUploadingPdf,
  uploadingPdfTitle = "template.pdf",
  onCloseButtonClick,
}) => {
  const styles = {
    container: {
      position: "absolute",
      right: "30px",
      bottom: "50px",
      width: "363px",
      height: "95px",
      backgroundColor: "white",
      border: "1px solid rgb(220, 248, 225)",
      boxShadow: "0px 2px 8px 0px rgb(220, 248, 225)",
      borderRadius: "3px",
      fontSize: "14px",
    },
    header: {
      height: "45px",
      backgroundColor: "rgb(106, 201, 121)",
      color: "white",
      borderTopLeftRadius: "3px",
      borderTopRightRadius: "3px",
    },
    mainContent: {
      height: "50px",
      color: " rgb(33, 37, 41)",
    },
    closeButton: {
      display: "inline-block",
      backgroundColor: "transparent",
      padding: "0",
      margin: "0",
      verticalAlign: "middle",
      border: "0px",
      outline: "none",
    },
    title: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "250px",
    },
  };

  return (
    <div style={styles.container}>
      <div
        style={styles.header}
        className="d-flex align-items-center justify-content-between px-4"
      >
        <div>{ifUploadingPdf ? "Uploading PDF" : "Pdf Upload Complete"}</div>
        <button style={styles.closeButton} onClick={onCloseButtonClick}>
          <img
            src={pdfUploadCloseIcon}
            style={{ height: "18px", marginRight: "3px" }}
          />
        </button>
      </div>
      <div
        style={styles.mainContent}
        className="d-flex align-items-center justify-content-between px-4"
      >
        <div className="d-flex align-items-center justify-content-start">
          <img src={pdfIcon} style={{ marginRight: "9px", height: "19px" }} />
          <div style={styles.title}>{uploadingPdfTitle}</div>
        </div>
        {ifUploadingPdf ? (
          <Lottie
            animationData={pdfUploadingLottie}
            style={{ width: "150px", position: "absolute", right: "-38px" }}
          />
        ) : (
          <img style={{ height: "22px" }} src={pdfUploadCompleteIcon} />
        )}
      </div>
    </div>
  );
};

export default PdfUploadingStatusBar;
