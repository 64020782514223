import React, { useEffect, useCallback, useState } from "react";
import UseFirestoreForPdfListener from "../../../firestore/useFirestoreForPdfListener";
import UseFirebaseForPdfAnnotations from "../../../firestore/useFirestoreForPdfAnnotations";
import UseFirestoreForPdfEditedTranslations from "../../../firestore/useFirestoreForPdfEditedTranslations";
import useAnalytics from "../../../analytics/useAnalytics";

const PdfListener = ({
  children,
  onClosePdfViewer,
  showPdfUrlRef,
  showPdfTitleRef,
  showPdfIdRef,
  ifShowPdf,
}) => {
  const [pdfViewerLoaded, setPdfViewerLoaded] = useState(false);
  const {
    handleNewCollectedWord,
    handleNewMasteredWord,
    handleNewWordTranslation,
  } = UseFirestoreForPdfListener(
    pdfViewerLoaded,
    showPdfUrlRef,
    showPdfTitleRef
  );
  const { handleNewAnnotation } = UseFirebaseForPdfAnnotations(
    pdfViewerLoaded,
    showPdfIdRef,
    ifShowPdf
  );
  const { handleNewEditedTranslation } = UseFirestoreForPdfEditedTranslations(
    pdfViewerLoaded,
    showPdfIdRef,
    ifShowPdf
  );
  const { logEvent } = useAnalytics();

  const handlePdfjsMessage = useCallback(
    (event) => {
      const msg = event.data;
      if (msg.source !== "pdfjs") {
        return;
      }
      switch (msg.command) {
        case "pdfInit":
          setPdfViewerLoaded(true);
          break;
        case "closePdfViewer":
          console.log("close pdf viewer");
          onClosePdfViewer();
          setPdfViewerLoaded(false);
          break;
        case "newCollectedWord":
          console.log("pdfListener: " + "newCollectedWord");
          var wordHistory = msg.data;
          wordHistory.url = showPdfUrlRef.current;
          handleNewCollectedWord(wordHistory);
          break;
        case "newMasteredWord":
          console.log("pdfListener: " + "newMasteredWord");
          var wordHistory = msg.data;
          wordHistory.url = showPdfUrlRef.current;
          handleNewMasteredWord(wordHistory);
          break;
        case "newWordTranslation":
          console.log("pdfListener: " + "newWordTranslation");
          var wordHistory = msg.data;
          wordHistory.url = showPdfUrlRef.current;
          handleNewWordTranslation(wordHistory);
          break;
        case "newAnnotation":
          var annotation = msg.data;
          handleNewAnnotation(annotation);
          break;
        case "turn-off-word-prediction":
          logEvent(
            "turn-off-word-prediction",
            "turn-off-word-prediction-web-pdf"
          );
          break;
        case "new-edited-translation":
          var newEditedTranslation = msg.data;
          handleNewEditedTranslation(newEditedTranslation);
          break;
        case "turn-on-word-prediction":
          logEvent(
            "turn-on-word-prediction",
            "turn-on-word-prediction-web-pdf"
          );
          break;
        case "play-word-audio":
          logEvent("play-word-audio", "play-word-audio-web-pdf");
          break;
        case "web-pdf-translate":
          logEvent("web-pdf-translate", msg.data);
          break;
        default:
          //For all shortcut web pdf stuff
          logEvent(msg.command, msg.command + "-shortcut-web-pdf");
          break;
      }
    },
    [handleNewCollectedWord, handleNewMasteredWord]
  );

  useEffect(() => {
    window.addEventListener("message", handlePdfjsMessage, false);
  }, [handlePdfjsMessage]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default PdfListener;
