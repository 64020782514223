import React from "react";

const Footer = () => {
  const styles = { button: { color: "black" } };
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light nav-bar w-100"
      // style={{ backgroundColor: "#d1f1e0" }}
    >
      <div className="row w-100 h-100 py-4">
        <div className="col d-flex justify-content-center w-100">
          <div
            className="col d-flex justify-content-between  "
            style={{ maxWidth: "1080px" }}
          >
            <a href="mailto:wordleap@gmail.com">
              <button className="btn btn-link" style={styles.button}>
                Contact
              </button>
            </a>

            <button
              className="btn btn-link"
              disabled={true}
              style={styles.button}
            >
              @ WordLeap 2021
            </button>

            <a href="https://twitter.com/WordLeapApp">
              <button className="btn btn-link" style={styles.button}>
                Twitter
              </button>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Footer;
