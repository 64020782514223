import firebase from "../services/firebase";
// import useChrome from "../chrome/useChrome";
import { useTranslation } from "react-i18next";
import useAnalytics from "../analytics/useAnalytics";
import UseMailChimp from "./useMailChimp";

function useAuth() {
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const { addNewSignUpEmailToMailChimp } = UseMailChimp();
  // const { chromeSignIn, chromeSignOut } = useChrome();

  const signInWithPassword = async (email, password) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        logEvent("sign-in-success", t("sign-in-success-label"));
        // chromeSignIn(email, password);
      })
      .catch(function (error) {
        console.log(error.message);
        throw error;
      });
  };

  const signUpWithPassword = async (email, password) => {
    await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        // chromeSignIn(email, password);
        addNewSignUpEmailToMailChimp(email);
        logEvent("sign-up-success", t("sign-up-success-label"));
      })
      .catch(function (error) {
        console.log(error.message);
        throw error;
      });
  };

  const signOut = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // chromeSignOut();
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  const signInWithGoogle = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    await firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((result) => {
        var isNewUser = result.additionalUserInfo.isNewUser;
        var email = result.user.email;
        if (isNewUser) {
          logEvent("sign-up-success", t("sign-up-with-google-success-label"));
          addNewSignUpEmailToMailChimp(email);
        } else {
          logEvent("sign-in-success", t("sign-in-with-google-success-label"));
        }
      })
      .catch((error) => {
        logEvent(
          "continue-with-google-cancelled",
          t("continue-with-google-cancelled-label")
        );
        console.log(error.message);
      });
  };

  return {
    signInWithPassword,
    signInWithGoogle,
    signUpWithPassword,
    signOut,
  };
}

export default useAuth;
