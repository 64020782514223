import React from "react";

import { useHistory } from "react-router-dom";

import HomePageContentTitle from "../homePageContentTitle";
import CaptionDocumentCard from "./captionDocumentCard";

import useCaptionDocuments from "./useCaptionDocuments";

const MeetingCaptionContent = () => {
  const { displayCaptionDocuments } = useCaptionDocuments();
  let history = useHistory();

  const handleStartMeetingCaption = () => {
    history.push("/newcaption");
  };

  return (
    <React.Fragment>
      <HomePageContentTitle title="Captions">
        <div className="mx-0">
          <button
            className="btn btn-primary"
            onClick={handleStartMeetingCaption}
          >
            New Caption
          </button>
        </div>
      </HomePageContentTitle>
      <div>
        {displayCaptionDocuments.length !== 0 &&
          displayCaptionDocuments.map((captionDocument) => (
            <CaptionDocumentCard
              key={captionDocument.initDate}
              captionDocument={captionDocument}
            />
          ))}
      </div>
    </React.Fragment>
  );
};

export default MeetingCaptionContent;
