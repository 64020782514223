import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import useFirestore from "../../../firestore/useFirestore";
import UseTranscription from "../../../transcription/useTranscription";
import CaptionHead from "../../../transcription/captionHead";
import TranscriptionArea from "../../../transcription/transcriptionArea";

const NewCaptionContent = () => {
  let history = useHistory();

  const [title, setTitle] = useState("New Caption");
  const initDate = new Date();

  const [shouldTranscribe, setShouldTranscribe] = useState(true);
  // const [transcriptionHeight, setTranscriptionHeight] = useState(0);
  // const transcriptionAreaHeight = "250";

  const handleTranscriptionChange = () => {
    // console.log(transcriptionRef.current.clientHeight);
    // if (transcriptionRef.current) {
    //   setTranscriptionHeight(transcriptionRef.current.clientHeight);
    // }
  };

  const {
    errorMessage,
    unknownWordArray,
    transcriptionArray,
    handlePauseTranscription,
    handleStartTranscription,
    unknownWordArrayRef,
    transcriptionArrayRef,
  } = UseTranscription(handleTranscriptionChange, title, initDate);

  const { uploadNewCaption } = useFirestore();

  const handleEndMeetingCaption = () => {
    setShouldTranscribe(false);
    handlePauseTranscription();
  };

  const handleRestartMeetingCaption = () => {
    setShouldTranscribe(true);
    handleStartTranscription();
  };

  const styles = {
    newCaptionContainer: {
      WebkitBoxFlex: "1",
      display: "flex",
      WebkitBoxDirection: "normal",
      WebkitBoxOrient: "vertical",
      flexFlow: "column",
      flex: "1",
    },
    newCaptionContainerBody: {
      userSelect: "none",
      WebkitBoxDirection: "normal",
      maxWidth: "40rem",
      margin: "0 auto",
      width: "100%",
    },
    newCaptionContainerHead: {
      borderBottom: "1px solid #8D8D8D",
      position: "sticky",
      top: "0rem",
      zIndex: "5",
      display: "flex",
      WebkitBoxOrient: "vertical",
      WebkitBoxDirection: "normal",
      flexDirection: "column",
      WebkitBoxPack: "start",
      justifyContent: "flex-start",
      WebkitBoxAlign: "stretch",
      alignItems: "stretch",
      paddingLeft: "1rem",
      paddingBottom: "0.3rem",
      paddingTop: "3rem",
      marginRight: "-1rem",

      backgroundColor: "white",
    },
    newCaptionContainerContent: {
      WebkitFontSmoothing: "antialiased",
      paddingTop: "1rem",
      paddingBottom: "2rem",
      display: "flex",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      flexFlow: "row wrap",
      WebkitBoxPack: "start",
      justifyContent: "flex-start",
      WebkitBoxAlign: "start",
      alignItems: "flex-start",
      alignContent: "flex-start",
    },
    newCaptionContainerFoot: {
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
      letterSpacing: "0",
      userSelect: "none",
      WebkitBoxDirection: "normal",
      minHeight: "calc(100vh - 270px)",
    },
    newCaptionContainerBottomBar: {
      // transitionDuration: ".4s",
      // transitionTimingFunction: "cubic-bezier(.25,.8,.25,1)",
      zIndex: "4",
      boxSizing: "border-box",
      position: "sticky",
      bottom: "3rem",
      transitionProperty: "none",
      width: "inherit",
      maxWidth: "inherit",
    },
  };

  const handleStopCaptionButtonClick = async () => {
    handlePauseTranscription();
    await uploadNewCaption(
      title,
      initDate,
      transcriptionArrayRef.current,
      unknownWordArrayRef.current
    );
    history.push("/captions");
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div id="new-caption-container" style={styles.newCaptionContainer}>
      <div style={styles.newCaptionContainerBody}>
        <div style={styles.newCaptionContainerHead}>
          <CaptionHead title={title} date={initDate} setTitle={setTitle} />
        </div>
        <div style={styles.newCaptionContainerContent}>
          <TranscriptionArea
            transcriptionArray={transcriptionArray}
            unknownWordArray={unknownWordArray}
          />
        </div>
        <div style={styles.newCaptionContainerFoot}></div>
        <div style={styles.newCaptionContainerBottomBar}>
          {errorMessage !== "" && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          <div className="d-flex justify-content-between">
            <div>
              {shouldTranscribe && errorMessage === "" && (
                <button
                  className="btn btn-primary "
                  onClick={handleEndMeetingCaption}
                >
                  Pause Caption
                </button>
              )}
              {(!shouldTranscribe || errorMessage !== "") && (
                <button
                  className="btn btn-primary "
                  onClick={handleRestartMeetingCaption}
                >
                  Restart Caption
                </button>
              )}
            </div>

            <div>
              <button
                className="btn btn-primary"
                onClick={handleStopCaptionButtonClick}
              >
                Stop Caption
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCaptionContent;
