import React, { useState, useEffect, useCallback } from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

import pdfIcon from "../../../assets/pdfIcon.svg";
import UseFirebaseForPdf from "../../../firestore/useFirestoreForPdf";
import UseFirestoreForWordList from "../../../firestore/useFirestoreForWordList";

import soundIcon from "../../../assets/soundIcon.svg";
import starOutline from "../../../assets/starOutline.svg";
import starFilled from "../../../assets/starFilled.svg";

const WordCard = ({
  wordDoc,
  playingAudio,
  setPlayingAudio,
  noWordParagraph,
  //hasNoWord is true when no word has been collected
  hasNoWord = false,
}) => {
  const { pdfDocsLoaded, getPdfTitle } = UseFirebaseForPdf();
  const { handleNewCollectedWord, handleNewMasteredWord } =
    UseFirestoreForWordList();
  const wordCardID = "wordCard" + wordDoc.word;
  const [translation, setTranslation] = useState("");
  const [sentenceHTML, setSentenceHTML] = useState("");
  const [pageIconUrl, setPageIconUrl] = useState("");
  const [url, setUrl] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [ifCollected, setIfCollected] = useState(true);
  const [hasWebsiteInfo, setHasWebsiteInfo] = useState(true);

  // set title based on url
  useEffect(() => {
    if (!pdfDocsLoaded) return;
    const title = getPdfTitle(wordDoc.url);
    setPageTitle(title);
  }, [pdfDocsLoaded, getPdfTitle]);

  // set translation, sentenceHtml, ifCollected, hasWebsiteInfo, pageIconUrl
  useEffect(() => {
    if (hasNoWord) {
      setTranslation(noWordParagraph);
      return;
    }
    if (!wordDoc.hasOwnProperty("translation")) {
      getTranslation(wordDoc.word);
    } else {
      if (wordDoc.translation === "") {
        getTranslation(wordDoc.word);
      } else {
        setTranslation(wordDoc.translation);
      }
    }

    setSentenceHTML(getSentenceHTML(wordDoc.word, wordDoc.sentence));
    setIfCollected(wordDoc.status === "collected");
    setHasWebsiteInfo(true);
    setPageIconUrl(pdfIcon);
  }, [wordDoc, hasNoWord, noWordParagraph]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getSentenceHTML = useCallback((keyword, originalText) => {
    var text = originalText;
    var regexp, replaceD;
    //keyword
    regexp = new RegExp("\\b" + keyword + "\\b", "g");
    replaceD = "<B>" + keyword + "</B>";
    text = text.replace(regexp, replaceD);
    //capitalized
    const capitalizedKeyword = capitalizeFirstLetter(keyword);
    regexp = new RegExp("\\b" + capitalizedKeyword + "\\b", "g");
    replaceD = "<B>" + capitalizedKeyword + "</B>";
    text = text.replace(regexp, replaceD);
    //all capitalized
    const allCapitalizedKeyword = keyword.toUpperCase();
    regexp = new RegExp("\\b" + allCapitalizedKeyword + "\\b", "g");
    replaceD = "<B>" + allCapitalizedKeyword + "</B>";
    text = text.replace(regexp, replaceD);
    //Wrapped by ""
    const wrappedKeyword = '"' + keyword + '"';
    regexp = new RegExp("\\b" + wrappedKeyword + "\\b", "g");
    replaceD = "<B>" + wrappedKeyword + "</B>";
    text = text.replace(regexp, replaceD);
    return text;
  }, []);

  const getTranslation = (keyword) => {
    const settings = {
      async: true,
      crossDomain: true,
      url: "https://google-translate1.p.rapidapi.com/language/translate/v2",
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        // "accept-encoding": "application/gzip",
        "x-rapidapi-key": "89561a7c12msh44b07092cf18492p12e4d1jsn762b4ac816c6",
        "x-rapidapi-host": "google-translate1.p.rapidapi.com",
      },
      data: {
        q: keyword,
        source: "en",
        target: "zh-CN",
      },
    };

    $.ajax(settings).done(function (response) {
      const translation = response.data.translations[0].translatedText;
      setTranslation(translation);
    });
  };

  const styles = {
    cardBody: { padding: "16px" },
    cardTitleRow: {
      marginBottom: "12px",
    },
    word: {
      textTransform: "capitalize",
      fontSize: "17px",
      height: "18px",
      marginBottom: "0px",
      marginRight: "8px",
      color: "#33A747",
    },
    audioButton: {
      margin: "0px",
      padding: "0px",
      border: "0px",
      backgroundColor: "white",
    },
    translation: {
      fontSize: "14px",
      height: "18px",
      marginBottom: "8px",
      color: "#373737",
      // fontWeight: "500",
    },
    sentence: {
      fontSize: "14px",
      lineHeight: "19px",
      height: "57px",
      overflow: "hidden",
      marginBottom: "16px",
      color: "#373737",
    },
    pageTitle: {
      fontSize: "12px",
      lineHeight: "14px",
      height: "14px",
      color: "#8D8D8D",
      textOverflow: "ellipsis",
      overflow: "hidden",
      marginBottom: "0px",
      paddingTop: "0px",
    },
    card: {
      width: "320px",
      border: "0",
      borderRadius: "8px",
      boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 8px 0px",
    },
  };

  const handleAudioButtonClick = () => {
    const keyword = wordDoc.word;

    if (!playingAudio) {
      //check if speech synthesis is supported
      if ("speechSynthesis" in window) {
        // Speech Synthesis supported 🎉
      } else {
        // Speech Synthesis Not Supported 😣
        alert("Sorry, your browser doesn't support text to speech!");
        return;
      }
      var msg = new SpeechSynthesisUtterance();
      msg.text = keyword;
      setPlayingAudio(true);
      msg.onend = function () {
        setPlayingAudio(false);
      };
      window.speechSynthesis.speak(msg);
    }
  };

  const handleStarButtonClick = () => {
    if (ifCollected) {
      // editWordCollectStatus(wordDoc.word, false);
      var wordHistory = {
        word: wordDoc.word,
        action: "master",
        // sentence: "",
        // translation: "",
        // url: "",
        date: new Date().toISOString(),
        platform: "web-wordlist",
      };
      handleNewMasteredWord(wordHistory);
      setIfCollected(false);
    } else {
      // editWordCollectStatus(wordDoc.word, true);
      var wordHistory = {
        word: wordDoc.word,
        action: "collect",
        // sentence: "",
        // translation: "",
        // url: "",
        date: new Date().toISOString(),
        platform: "web-wordlist",
      };
      handleNewCollectedWord(wordHistory);
      setIfCollected(true);
    }
  };

  return (
    <React.Fragment>
      <div className="mr-4">
        <div className="my-3 card" style={styles.card}>
          <div
            className={
              hasNoWord
                ? "card-body d-flex flex-column justify-content-end mb-5 mt-3"
                : "card-body"
            }
            style={styles.cardBody}
          >
            <div
              className="d-flex justify-content-between align-items-center first-row"
              style={styles.cardTitleRow}
            >
              <div className="d-flex justify-content-start align-items-center">
                <h5 style={styles.word}>{wordDoc.word}</h5>
                {!hasNoWord && (
                  <button
                    className="btn btn-link"
                    style={styles.audioButton}
                    onClick={handleAudioButtonClick}
                  >
                    <img src={soundIcon} alt="sound-icon" height="13" />
                  </button>
                )}
              </div>
              <div>
                {!hasNoWord && (
                  <React.Fragment>
                    <button
                      className="btn btn-link"
                      style={styles.audioButton}
                      onClick={handleStarButtonClick}
                      data-tip
                      data-for={wordCardID}
                    >
                      <img
                        src={ifCollected ? starFilled : starOutline}
                        alt="sound-icon"
                        height="13"
                      />
                    </button>

                    <ReactTooltip
                      id={wordCardID}
                      place="top"
                      effect="solid"
                      type="success"
                      backgroundColor="#33A747"
                    >
                      {ifCollected ? "Master" : "Collect"}
                    </ReactTooltip>
                  </React.Fragment>
                )}
              </div>
            </div>
            <p className="second-row" style={styles.translation}>
              {translation}
            </p>
            {!hasNoWord && (
              <React.Fragment>
                <p
                  style={styles.sentence}
                  className="third-row"
                  dangerouslySetInnerHTML={{ __html: sentenceHTML }}
                />
                {hasWebsiteInfo && (
                  <a href={url}>
                    <div className="d-flex justify-content-start align-items-center last-row">
                      <img
                        src={pageIconUrl}
                        alt="page-icon"
                        style={{
                          height: "13px",
                          width: "13px",
                          marginRight: "4px",
                        }}
                      />

                      <p className="card-link" style={styles.pageTitle}>
                        {pageTitle}
                      </p>
                    </div>
                  </a>
                )}
                {!hasWebsiteInfo && (
                  <div className="d-flex justify-content-start align-items-center last-row">
                    <div
                      style={{
                        // height: "13px",
                        width: "13px",
                        marginRight: "6px",
                      }}
                    />
                    <p className="card-link" style={styles.pageTitle}></p>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WordCard;
