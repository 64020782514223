import React from "react";
import { useTranslation } from "react-i18next";

import logoWithTitleInGrey from "../../assets/logoWithTitleInGrey.png";

import LanguageSelector from "./languageSelector";
import TryNowButton from "./tryNowButton";

const NavBar = ({
  page,
  primaryCTAVisible,
  signMeUpButtonVisible,
  onNavTryNow,
}) => {
  // const handleAddToChromeClick = () => {
  //   logEvent("add_to_chrome", { method: "navbar" });
  //   window.location =
  //     "https://chrome.google.com/webstore/detail/wordleap/bepijhmmhmacofkkmppabmicaajljbda?hl=en-US";
  // };
  const { t } = useTranslation();

  return (
    <nav
      className={"navbar navbar-expand-lg navbar-light nav-bar fixed-top"}
      style={{
        backgroundColor: primaryCTAVisible
          ? "transparent"
          : "rgba(255, 255, 255, 0.97)",
      }}
    >
      <div className="d-flex w-100 justify-content-between align-items-center">
        <a className="navbar-brand" href="https://www.wordleap.co/">
          <img src={logoWithTitleInGrey} alt="Logo" style={{ height: 25 }} />
        </a>
        <div className="d-flex align-items-center">
          {/* <button
            style={{
              height: 32,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <IconContext.Provider
              value={{
                color: "rgb(33, 37, 41)",
              }}
            >
              <IoMdGlobe />
            </IconContext.Provider>
          </button> */}

          <LanguageSelector />

          {page !== "personalize" &&
            !primaryCTAVisible &&
            !signMeUpButtonVisible && (
              <button
                className="btn btn-primary btn-sm my-2 my-sm-0 d-flex justify-content-center align-items-center"
                style={{
                  height: 32,
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
                onClick={onNavTryNow}
              >
                <div
                  style={{
                    marginRight: "6px",
                    fontWeight: t("try-now-button-font-weight"),
                  }}
                >
                  {t("try-now-button")}
                </div>
                <div style={{ fontWeight: "300" }}>{t("its-free")}</div>
              </button>
            )}
        </div>

        {page === "personalize" && (
          <p className="my-2 my-sm-0" style={{ height: 29, fontWeight: "500" }}>
            Welcome
          </p>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
