import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "./i18n";
import App from "./App";
import "./index.css";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>WordLeap - English PDF Reading, Made Easy</title>
      <meta
        name="title"
        property="og:title"
        content="WordLeap - English PDF Reading, Made Easy"
      />
      <meta
        name="description"
        property="og:description"
        content="WordLeap is a PDF reader that helps non-native English speakers understand unfamiliar expressions instantly without refering to dictionaries"
      />
      <meta
        name="image"
        property="og:image"
        content="https://github.com/Andy-ZYA/OGImage/blob/main/open-graph-image.png?raw=true"
      />
      <meta name="type" property="og:type" content="website" />
      <meta
        name="facebook-domain-verification"
        content="045djcg2qh5043jh7k8704f6rimmk2"
      />
    </Helmet>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
