import { useEffect, useCallback } from "react";
import $ from "jquery";

const UseCloudServerColdStart = () => {
  const MINUTE_MS = 30000;

  useEffect(() => {
    solveCloudRunColdStart();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      //   console.log("Logs every 30s");
      solveCloudRunColdStart();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const solveCloudRunColdStart = useCallback(() => {
    // console.log("cold start");
    const serverUrl = "https://wordly-server-hlxo6jmzoq-uc.a.run.app/";
    const currentURL = window.location.href;
    const html = "<div></div>";
    const settings = {
      async: true,
      crossDomain: true,
      url: serverUrl + "text",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": currentURL,
      },
      data: {
        url: currentURL,
        html: html,
        uid: "",
      },
    };
    $.ajax(settings).done(function (response) {
      const wordListToHighlight = response;
      //   console.log(wordListToHighlight);
    });
  }, []);
};

export default UseCloudServerColdStart;
