const useDate = () => {
  const getDisplayDate = (date) => {
    if (typeof date === "string") {
      var dateTemp = new Date(date);
    } else {
      dateTemp = date;
    }
    const displayDate =
      dateTemp.getDate() +
      "/" +
      (dateTemp.getMonth() + 1) +
      "/" +
      dateTemp.getFullYear() +
      ", " +
      dateTemp.getHours() +
      ":" +
      dateTemp.getMinutes();
    return displayDate;
  };
  return { getDisplayDate };
};

export default useDate;
