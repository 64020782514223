import React, { useRef, useEffect } from "react";

const PdfViewer = ({ src }) => {
  const viewerRef = useRef();
  // const src = "/pdf.pdf";

  const init = (source, element) => {
    const iframe = document.createElement("iframe");
    // console.log(source);
    iframe.src = `/pdfjs-2.6.347-dist/web/viewer.html?file=${encodeURIComponent(
      source
    )}`;

    // iframe.src = `/pdfjs-2.8.335-dist/web/viewer.html?file=${source}`;
    iframe.id = "wordleap-iframe";
    iframe.width = "100%";
    iframe.height = "100%";
    iframe.style.border = "0px";
    element.appendChild(iframe);
  };

  useEffect(() => {
    const element = viewerRef.current;
    init(src, element);
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
        margin: "0px",
        backgroundColor: "rgba(248, 248, 248, 0.85)",
        zIndex: "1000000",
      }}
    >
      <div
        ref={viewerRef}
        id="viewer"
        style={{ width: "100%", height: "100%" }}
      ></div>
    </div>
  );
};

export default PdfViewer;
