import React from "react";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";

const TryNowButton = ({ width = "200px", onTryNow, onButtonVisibleChange }) => {
  const { t } = useTranslation();

  return (
    <VisibilitySensor onChange={onButtonVisibleChange}>
      <button
        className="btn btn-primary d-flex justify-content-center align-items-center"
        style={{ width: width, height: "44px" }}
        onClick={onTryNow}
      >
        <div
          style={{
            marginRight: "8px",
            fontWeight: t("try-now-button-font-weight"),
          }}
        >
          {t("try-now-button")}
        </div>
        <div style={{ fontWeight: "300" }}>{t("its-free")}</div>
      </button>
    </VisibilitySensor>
  );
};

export default TryNowButton;
