import { useContext } from "react";

import firebase from "../services/firebase";
import AuthContext from "../context/authContext";

const useFirebaseForPersonalization = () => {
  const { user } = useContext(AuthContext);
  const db = firebase.firestore();

  const skipPersonalization = () => {
    const uid = user.uid;
    db.collection("users")
      .doc(uid)
      .set(
        {
          personalized: "skipped",
        },
        { merge: true }
      )
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  const uploadPersonalizationInfo = async (info) => {
    const uid = user.uid;
    info.forEach((infoEntry) => {
      db.collection("users")
        .doc(uid)
        .set(infoEntry, { merge: true })
        .then(function () {
          console.log("Document successfully written!");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    });

    await db
      .collection("users")
      .doc(uid)
      .set(
        {
          personalized: true,
        },
        { merge: true }
      )
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  const uploadNativeLanguage = (infoEntry) => {
    const uid = user.uid;
    db.collection("users")
      .doc(uid)
      .set(infoEntry, { merge: true })
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  return {
    skipPersonalization,
    uploadPersonalizationInfo,
    uploadNativeLanguage,
  };
};

export default useFirebaseForPersonalization;
