import { useEffect, useRef, useCallback } from "react";

const useChineseHighSchoolWords = () => {
  const chineseHighSchoolWordsRef = useRef([]);

  const getChineseHighSchoolWords = useCallback(async () => {
    const response = await fetch("data/ChineseHighSchoolEnglishWords.csv");
    const reader = response.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder("utf-8");
    const data = await decoder.decode(result.value);
    chineseHighSchoolWordsRef.current = data.split("\n");
    for (var i = 0; i < chineseHighSchoolWordsRef.current.length; i++) {
      chineseHighSchoolWordsRef.current[i] = chineseHighSchoolWordsRef.current[
        i
      ].trim();
    }
  }, []);

  //OnMount
  useEffect(() => {
    getChineseHighSchoolWords();
  }, [getChineseHighSchoolWords]);

  return { chineseHighSchoolWordsRef };
};

export default useChineseHighSchoolWords;
