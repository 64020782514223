import React, { useState, useRef, useCallback } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// import wordleapGif from "../../assets/wordleapGif.gif";
// import struggle from "../../assets/struggle.png";
// import personalization from "../../assets/personalization.png";
// import listeningWithoutPausing from "../../assets/listeningWithoutPausing.png";
import pdfReaderGif from "../../assets/pdfReader.gif";
import seeTranslatonWithNoClickGif from "../../assets/seeTranslationWithNoClick.gif";
import translationInContext from "../../assets/translationInContext.png";
// import learnSmarterNotHarder from "../../assets/learnSmarterNotHarder.png";
import learnSmarterNotHarder from "../../assets/learnSmarterNotHarder3.gif";
import requestEarlyAccessBackground from "../../assets/requestEarlyAccessBackground.png";
import accessAnywhere from "../../assets/accessAnywhere3.png";

// import HowItWorks from "./howItWorksNewChinese";
import NavBar from "./navBar";
import Footer from "./footer";
import SectionWrapper from "./sectionWrapper";
import SectionContent from "./sectionContent";
import BottonFormSection from "./bottomFormSection";
import useAnalytics from "../../analytics/useAnalytics";
// import SimplifiedFormSection from "./simplifiedFormSection";
import TryNowButton from "./tryNowButton";
import AuthPopup from "./authPopup";

const LandingPage = () => {
  const mailChimpUrl =
    "https://wordleap.us1.list-manage.com/subscribe/post?u=0478b7fc376981b2db932f692&amp;id=e58c5ef85f";
  const history = useHistory();
  const { t } = useTranslation();
  const [primaryCTAVisible, setPrimaryCTAVisible] = useState(true);
  const [signMeUpButtonVisible, setSignMeUpButtonVisible] = useState(false);
  const [ifRequested, setIfRequested] = useState(false);
  const [ifShowAuthPopup, setIfShowAuthPopup] = useState(false);
  const formRef = useRef(null);
  const { logEvent } = useAnalytics();

  // const scrollToForm = () => {
  //   formRef.current.scrollIntoView();
  // };

  const handleNavTryNow = () => {
    logEvent("try-now", t("try-now-button-label") + "nav");
    setIfShowAuthPopup(true);
    // history.push("./login");
    // scrollToForm();
  };

  const handleTopTryNow = () => {
    logEvent("try-now", t("try-now-button-label") + "primary");
    setIfShowAuthPopup(true);
    // history.push("./login");
  };

  const handleBottomTryNow = () => {
    logEvent("try-now", t("try-now-button-label") + "bottom");
    // history.push("./login");
    setIfShowAuthPopup(true);
  };

  const handleSignMeUpButtonVisibleChange = (isVisible) => {
    setSignMeUpButtonVisible(isVisible);
  };

  const handlePrimaryCTAVisibleChangee = (isVisible) => {
    setPrimaryCTAVisible(isVisible);
  };

  const handleIfRequestedChange = useCallback((state) => {
    setIfRequested(state);
  }, []);

  const handleClosePopup = () => {
    logEvent("close-auth-popup", t("close-auth-popup-label"));
    setIfShowAuthPopup(false);
  };

  return (
    <div className="d-flex flex-column align-items-center">
      {ifShowAuthPopup && <AuthPopup onClosePopup={handleClosePopup} />}

      <NavBar
        primaryCTAVisible={primaryCTAVisible}
        signMeUpButtonVisible={signMeUpButtonVisible}
        onNavTryNow={handleNavTryNow}
      />

      <SectionWrapper
        isFullScreen={true}
        backgroundStyle={{
          background:
            "radial-gradient(900px 900px at top right, rgba(223,239,236,1), #FFFFFF)",
        }}
      >
        <SectionContent
          title={t("homepage-title-1")}
          titleFirstVisibleEventLabel={t(
            "homepage-title-1-first-visible-event-label"
          )}
          titleType="h1"
          isFirstScreen={true}
          signUpForm={
            // <MailchimpSubscribe
            //   url={mailChimpUrl}
            //   render={({ subscribe, status, message }) => (
            //     <SimplifiedFormSection
            //       onButtonVisibleChange={handlePrimaryCTAVisibleChangee}
            //       status={status}
            //       message={message}
            //       onValidated={(formData) => subscribe(formData)}
            //       ifRequested={ifRequested}
            //       onIfRequestedChange={handleIfRequestedChange}
            //     />
            //   )}
            // />
            <TryNowButton
              onTryNow={handleTopTryNow}
              onButtonVisibleChange={handlePrimaryCTAVisibleChangee}
            />
          }
          img={pdfReaderGif}
          imgAlt="wordleapGif"
          isImgLeft={false}
          hasScrollIndication={true}
        >
          {t("homepage-description-1")}
        </SectionContent>
      </SectionWrapper>

      <SectionWrapper
        isFullScreen={false}
        backgroundStyle={{
          background:
            "radial-gradient(600px 600px at top left, #DFEFEC, #FFFFFF)",
        }}
      >
        <SectionContent
          title={t("homepage-title-2")}
          titleFirstVisibleEventLabel={t(
            "homepage-title-2-first-visible-event-label"
          )}
          hasButton={false}
          img={seeTranslatonWithNoClickGif}
          imgAlt="seeTranslatonWithNoClickGif"
          isImgLeft={true}
          imgHasShadow={true}
        >
          {t("homepage-description-2")}
        </SectionContent>
      </SectionWrapper>

      {/* <SectionWrapper
        isFullScreen={false}
        backgroundStyle={{
          background:
            "radial-gradient(600px 600px at top right, rgba(223,239,236,0.7), #FFFFFF)",
        }}
      >
        <HowItWorks />
      </SectionWrapper> */}

      <SectionWrapper
        isFullScreen={false}
        backgroundStyle={{
          background:
            "radial-gradient(600px 600px at top right, #DFEFEC, #FFFFFF)",
        }}
      >
        <SectionContent
          title={t("homepage-title-3")}
          titleFirstVisibleEventLabel={t(
            "homepage-title-3-first-visible-event-label"
          )}
          hasButton={false}
          img={translationInContext}
          imgAlt="translationInContext"
          isImgLeft={false}
          imgHasShadow={true}
        >
          {t("homepage-description-3")}
        </SectionContent>
      </SectionWrapper>

      <SectionWrapper isFullScreen={false}>
        <SectionContent
          title={t("homepage-title-4")}
          titleFirstVisibleEventLabel={t(
            "homepage-title-4-first-visible-event-label"
          )}
          hasButton={false}
          img={accessAnywhere}
          imgAlt="accessAnywhere"
          isImgLeft={true}
          imgHasShadow={false}
        >
          {t("homepage-description-4")}
        </SectionContent>
      </SectionWrapper>

      <SectionWrapper
        isFullScreen={false}
        backgroundStyle={{
          background:
            "radial-gradient(600px 600px at top left, #DFEFEC, #FFFFFF)",
        }}
      >
        <SectionContent
          title={t("homepage-title-5")}
          titleFirstVisibleEventLabel={t(
            "homepage-title-5-first-visible-event-label"
          )}
          hasButton={false}
          img={learnSmarterNotHarder}
          imgAlt="learnSmarterNotHarder"
          isImgLeft={false}
        >
          {t("homepage-description-5")}
        </SectionContent>
      </SectionWrapper>

      {/* <SectionWrapper
        isFullScreen={false}
        backgroundStyle={{
          background:
            "radial-gradient(600px 600px at top right, #DFEFEC, #FFFFFF)",
        }}
      >
        <SectionContent
          title={t("homepage-title-6")}
          hasButton={false}
          img={learnSmarterNotHarder}
          imgAlt="learnSmarterNotHarder"
          isImgLeft={false}
        >
          {t("homepage-description-6")}
        </SectionContent>
      </SectionWrapper> */}

      <SectionWrapper
        isFullScreen={false}
        cardStyle={{
          backgroundImage: `url(${requestEarlyAccessBackground})`,
          backgroundSize: "cover",
          borderRadius: "16px",
          width: "90%",
          paddingTop: "2.5rem",
          paddingBottom: "2.5rem",
        }}
        refProp={formRef}
      >
        <MailchimpSubscribe
          url={mailChimpUrl}
          render={({ subscribe, status, message }) => (
            <BottonFormSection
              onButtonVisibleChange={handleSignMeUpButtonVisibleChange}
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
              ifRequested={ifRequested}
              onIfRequestedChange={handleIfRequestedChange}
              onBottomTryNow={handleBottomTryNow}
            />
          )}
        />
      </SectionWrapper>

      <Footer />
    </div>
  );
};

export default LandingPage;
