const UseFirestoreNames = () => {
  //user collection
  const userCollName = "users";
  const collectedWordsFieldName = "unknownWordList";
  const masteredWordsFieldname = "uncollectedWordList";
  const personalizedFieldName = "personalized";
  //pdf collection
  const pdfCollName = "pdfs";
  //pdf annotation collection
  const pdfAnnotationCollName = "pdfAnnots";
  //pdf edited translation collection
  const pdfEditedTranslationCollName = "pdfEditedTrans";
  //word collection
  const wordCollName = "collectedWords";
  //user info collection
  const userInfoCollName = "userInfo";
  //OnboardingDoc and field name
  const onboardingDocName = "onboarding";
  const ifUploadedNewFieldName = "ifUploadedNewField";
  const ifOpenedPdfFieldName = "ifOpenedPdf";

  return {
    userCollName,
    collectedWordsFieldName,
    masteredWordsFieldname,
    personalizedFieldName,
    pdfCollName,
    pdfAnnotationCollName,
    pdfEditedTranslationCollName,
    wordCollName,
    userInfoCollName,
    onboardingDocName,
    ifUploadedNewFieldName,
    ifOpenedPdfFieldName,
  };
};

export default UseFirestoreNames;
