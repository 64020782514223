import UseFirestoreForWordList from "./useFirestoreForWordList";
import useFirebaseForPersonalization from "./useFirestoreForPersonalization";
import useFirestoreForPdf from "./useFirestoreForPdf";

const useFirestore = () => {
  const { editWordCollectStatus } = UseFirestoreForWordList();

  const {
    skipPersonalization,
    uploadPersonalizationInfo,
    uploadNativeLanguage,
  } = useFirebaseForPersonalization();

  const { uploadPdfFile } = useFirestoreForPdf();

  return {
    editWordCollectStatus,
    uploadPersonalizationInfo,
    uploadNativeLanguage,
    skipPersonalization,
    uploadPdfFile,
  };
};

export default useFirestore;
