import { useContext, useState, useEffect, useCallback } from "react";

import AuthContext from "../context/authContext";
import firebase from "../services/firebase";
import UseFirestoreNames from "../names/useFirestoreNames";

const onboardingStepNames = {
  uploadNewPdf: "UPLOAD_NEW_PDF",
  openPdfFile: "OPEN_PDF_FILE",
  finished: "finished",
};

const onboardingHotspotContent = {
  uploadNewPdf: {
    title: "Upload your first PDF",
    description:
      "Upload PDF documents here, and you can access them on any device",
  },
  openPdfFile: {
    title: "Tab here to open your pdf",
    description:
      "Open PDF to get in-context translation of unfamiliar words instantly",
  },
};

const useFirestoreForOnboarding = () => {
  const { user } = useContext(AuthContext);
  const [currentOnboarding, setCurrentOnboarding] = useState("none");
  const {
    userCollName,
    userInfoCollName,
    onboardingDocName,
    ifUploadedNewFieldName,
    ifOpenedPdfFieldName,
  } = UseFirestoreNames();
  const db = firebase.firestore();

  const parseOnboardingData = useCallback((onboardingData) => {
    // For ifUploadedNewFieldName
    if (!onboardingData) {
      console.log(onboardingData);
      setCurrentOnboarding(onboardingStepNames.uploadNewPdf);
      return;
    }
    // For ifOpenedPdfFieldName
    if (!onboardingData.hasOwnProperty(ifOpenedPdfFieldName)) {
      setCurrentOnboarding(onboardingStepNames.openPdfFile);
      return;
    }
    if (onboardingData[ifOpenedPdfFieldName]) {
      setCurrentOnboarding(onboardingStepNames.finished);
      return;
    }
  }, []);

  const handleFinishedUploadingPdf = () => {
    setOnboardingDocField(ifUploadedNewFieldName, true);
  };

  const handleFinishedOpenPdf = () => {
    setOnboardingDocField(ifOpenedPdfFieldName, true);
  };

  const setOnboardingDocField = (fieldName, value) => {
    var newData = {};
    newData[fieldName] = value;
    db.collection(userCollName)
      .doc(user.uid)
      .collection(userInfoCollName)
      .doc(onboardingDocName)
      .set(newData, { merge: true })
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  useEffect(() => {
    if (!user) return;
    db.collection(userCollName)
      .doc(user.uid)
      .collection(userInfoCollName)
      .doc(onboardingDocName)
      .onSnapshot(
        (doc) => {
          var onboardingData = doc.data();
          parseOnboardingData(onboardingData);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [user, parseOnboardingData]);

  return {
    currentOnboarding,
    handleFinishedUploadingPdf,
    handleFinishedOpenPdf,
    onboardingStepNames,
    onboardingHotspotContent,
  };
};

export default useFirestoreForOnboarding;
