import React from "react";

import TextColumn from "./textColumn";
import useWindowDimensions from "../common/useWindowDimensions";
import ImgColumn from "./imgColumn";
import ScrollForMoreIndication from "./scrollForMoreIndication";

const SectionContent = ({
  title,
  titleFirstVisibleEventLabel,
  titleType,
  isFirstScreen = false,
  children,
  hasButton,
  buttonText,
  onClick,
  onButtonVisibleChange,
  img,
  imgAlt,
  isImgLeft = false,
  signUpForm = false,
  imgHasShadow,
  hasScrollIndication = false,
}) => {
  const { isMobile } = useWindowDimensions();

  return (
    <React.Fragment>
      {isImgLeft && !isMobile && !isFirstScreen && (
        <ImgColumn img={img} imgAlt={imgAlt} imgHasShadow={imgHasShadow} />
      )}
      <TextColumn
        title={title}
        titleFirstVisibleEventLabel={titleFirstVisibleEventLabel}
        titleType={titleType}
        children={children}
        hasButton={hasButton}
        buttonText={buttonText}
        onClick={onClick}
        onButtonVisibleChange={onButtonVisibleChange}
        signUpForm={signUpForm}
      />
      {(!isImgLeft || isMobile || isFirstScreen) && (
        <ImgColumn img={img} imgAlt={imgAlt} imgHasShadow={imgHasShadow} />
      )}
      {hasScrollIndication && !isMobile && (
        // <div
        //   style={{
        //     position: "absolute",
        //     left: "0px",
        //     bottom: "30px",
        //     height: "10px",
        //     width: "100%",
        //     textAlign: "center",
        //     color: "rgb(140, 140, 140)",
        //   }}
        // >
        //   <AiOutlineDown /> {t("scroll-for-more")}
        // </div>
        <ScrollForMoreIndication />
      )}
    </React.Fragment>
  );
};

export default SectionContent;
