import React from "react";
import logoWithTitleInGrey from "../../assets/logoWithTitleInGrey.png";

const PersonalizationTopBar = ({ step, onPreviousQuestion }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center w-100 mx-0 px-0"
      style={{ height: "40px" }}
    >
      <div className="d-flex justify-content-start w-100">
        <button
          className="mx-3 btn btn-light btn-grey-link"
          onClick={onPreviousQuestion}
          disabled={step === 1}
        >
          Previous Question
        </button>
      </div>
      <div className="d-flex justify-content-center w-100">
        <div
          className="mx-3"
          disabled={true}
          style={{
            color: "rgb(33, 37, 41)",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          Question {step}/6
        </div>
      </div>

      <div className="d-flex justify-content-end  w-100">
        <img
          src={logoWithTitleInGrey}
          className="mx-3"
          alt="Logo"
          style={{ height: 23 }}
        />
      </div>
    </div>
  );
};

export default PersonalizationTopBar;
