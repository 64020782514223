import { useContext, useState, useEffect } from "react";

import firebase from "../services/firebase";
import AuthContext from "../context/authContext";
import UseFirestoreNames from "../names/useFirestoreNames";
import UseFirestoreForBasicWordOperations from "./useFirestoreForBasicWordOperations";

const UseFirestoreForWordList = () => {
  const [wordDocs, setWordDocs] = useState(null);
  const [wordDocsLoaded, setWordDocsLoaded] = useState(false);
  const { userCollName, wordCollName } = UseFirestoreNames();
  const { handleNewCollectedWord, handleNewMasteredWord } =
    UseFirestoreForBasicWordOperations();

  const { user } = useContext(AuthContext);
  const db = firebase.firestore();

  useEffect(() => {
    if (!user) return;
    db.collection(userCollName)
      .doc(user.uid)
      .collection(wordCollName)
      .onSnapshot(
        (querySnapshot) => {
          var wordDocs = [];
          querySnapshot.forEach(function (doc) {
            var word = doc.id;
            var wordDoc = doc.data();
            wordDoc.word = word;
            wordDocs.push(wordDoc);
          });
          // console.log(collectedWords);
          setWordDocs(wordDocs);
          // console.log(pdfDocs);
          setWordDocsLoaded(true);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [user]);

  return {
    wordDocs,
    wordDocsLoaded,
    handleNewCollectedWord,
    handleNewMasteredWord,
  };
};

export default UseFirestoreForWordList;
