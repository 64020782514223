import firebase from "firebase/app";

// import "firebase/analytics";
// Optionally import the services that you want to use
import "firebase/auth";
// import "firebase/database";
import "firebase/firestore";
// //import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyApIpe4AaG7JgZ5Itih09lYRKmBY86Sywk",
  authDomain: "wordly-43984.firebaseapp.com",
  databaseURL: "https://wordly-43984-default-rtdb.firebaseio.com",
  projectId: "wordly-43984",
  storageBucket: "wordly-43984.appspot.com",
  messagingSenderId: "754969416449",
  appId: "1:754969416449:web:c634cba08f1066485c0100",
  measurementId: "G-PTTQ6NEFM5",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
