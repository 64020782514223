import { useState, useEffect, useCallback, useRef } from "react";
import firebase from "../services/firebase";
// import useNestedArray from "../components/common/useNestedArray";
import UseFirestoreNames from "../names/useFirestoreNames";

const useAuthInit = () => {
  const {
    userCollName,
    collectedWordsFieldName,
    masteredWordsFieldname,
    personalizedFieldName,
  } = UseFirestoreNames();
  const db = firebase.firestore();
  //user related
  const [user, setUser] = useState(null);
  const userRef = useRef(null);
  const uidRef = useRef(null);
  const [loadingUser, setLoadingUser] = useState(true);
  //personalization related
  const [personalized, setPersonalized] = useState(null);
  // collectedWords and masteredWords
  const [collectedWords, setCollectedWords] = useState(null);
  const collectedWordsRef = useRef(null);
  const [masteredWords, setMasteredWords] = useState(null);
  const masteredWordsRef = useRef(null);
  // captions
  // const [captionDocuments, setCaptionDocuments] = useState([]);
  // const { objectToNestedArray } = useNestedArray();

  const initUserFirebase = useCallback(
    (user) => {
      var uid = user.uid;
      var userData = {};
      userData[collectedWordsFieldName] = [];
      userData[masteredWordsFieldname] = [];
      userData["noWordSuggestURLList"] = [];
      userData["youtubeSubtitleSetting"] = true;
      userData[personalizedFieldName] = false;
      db.collection(userCollName)
        .doc(uid)
        .set(userData)
        .then(function () {
          console.log("Document successfully written!");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    },
    [db]
  );

  const parseUserData = useCallback(
    (userData, user) => {
      var uid = user.uid;
      // console.log(uid);
      if (!userData) {
        setCollectedWords([]);
        collectedWordsRef.current = [];
        initUserFirebase(user);
        return;
      }
      //personalized
      if (userData[personalizedFieldName] !== undefined) {
        setPersonalized(userData[personalizedFieldName]);
      } else {
        setPersonalized(false);
        var newData = {};
        newData[personalizedFieldName] = false;
        db.collection(userCollName)
          .doc(uid)
          .set(newData, { merge: true })
          .then(function () {
            console.log("Document successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }
      //collectedWords
      if (userData[collectedWordsFieldName]) {
        setCollectedWords(userData[collectedWordsFieldName]);
        collectedWordsRef.current = userData[collectedWordsFieldName];
      } else {
        setCollectedWords([]);
        collectedWordsRef.current = [];
        var newData = {};
        newData[collectedWordsFieldName] = [];
        db.collection(userCollName)
          .doc(uid)
          .set(newData, { merge: true })
          .then(function () {
            console.log("Document successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }
      //masteredWords
      if (userData[masteredWordsFieldname]) {
        setMasteredWords(userData[masteredWordsFieldname]);
        masteredWordsRef.current = userData[masteredWordsFieldname];
      } else {
        setMasteredWords([]);
        var newData = {};
        newData[masteredWordsFieldname] = [];
        masteredWordsRef.current = [];
        db.collection("users")
          .doc(uid)
          .set(newData, { merge: true })
          .then(function () {
            console.log("Document successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }
    },
    [db, initUserFirebase]
  );

  const retrieveUserFirestoreData = useCallback(
    (user) => {
      if (user) {
        //get user data
        // console.log(user.uid);
        db.collection("users")
          .doc(user.uid)
          .onSnapshot(function (doc) {
            var userData = doc.data();
            parseUserData(userData, user);
          });

        // //get collected Words collection
        // db.collection("users")
        //   .doc(user.uid)
        //   .collection("collectedWords")
        //   .onSnapshot(function (querySnapshot) {
        //     var collectedWords = [];
        //     querySnapshot.forEach(function (doc) {
        //       // console.log(doc.id);
        //       var word = doc.id;
        //       // console.log(doc.data());
        //       var data = doc.data();
        //       data.word = word;
        //       collectedWords.push(data);
        //     });
        //     // console.log(collectedWords);
        //     setCollectedWords(collectedWords);
        //     setCollectedWordsLoaded(true);
        //   });
        //Get Auth Status
        // //get collected Words collection
        // db.collection("users")
        //   .doc(user.uid)
        //   .collection("captions")
        //   .onSnapshot(function (querySnapshot) {
        //     var captionsTemp = [];
        //     querySnapshot.forEach(function (doc) {
        //       var data = doc.data();
        //       var unknownWordArray = data.unknownWordArray;
        //       data.unknownWordArray = objectToNestedArray(unknownWordArray);
        //       captionsTemp.push(data);
        //     });
        //     // console.log(captionsTemp);
        //     setCaptionDocuments([...captionsTemp]);
        //   });
      }
    },
    [db, parseUserData]
  );

  useEffect(() => {
    //Get Auth Status
    firebase.auth().onAuthStateChanged((newUser) => {
      if (!newUser) {
        // const [user, setUser] = useState(null);
        setUser(null);
        // const userRef = useRef(null);
        userRef.current = null;
        // const uidRef = useRef(null);
        uidRef.current = null;
        // const [loadingUser, setLoadingUser] = useState(true);
        setLoadingUser(true);
        // //personalization related
        // const [personalized, setPersonalized] = useState(null);
        setPersonalized(null);
        // // collectedWords and masteredWords
        // const [collectedWords, setCollectedWords] = useState(null);
        setCollectedWords(null);
        // const collectedWordsRef = useRef(null);
        collectedWordsRef.current = null;
        // const [masteredWords, setMasteredWords] = useState(null);
        setMasteredWords(null);
        // const masteredWordsRef = useRef(null);
        masteredWordsRef.current = null;
        return;
      }
      setUser(newUser);
      userRef.current = newUser;
      uidRef.current = newUser.uid;
      setLoadingUser(false);
      retrieveUserFirestoreData(newUser);
    });
  }, [db, parseUserData, retrieveUserFirestoreData]);

  return {
    //user related
    user,
    userRef,
    uidRef,
    loadingUser,
    //personalization
    personalized,
    //collectedWords and masteredWords
    collectedWords,
    collectedWordsRef,
    masteredWords,
    masteredWordsRef,
  };
};

export default useAuthInit;
